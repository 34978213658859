import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { registerapi } from "../../../Store/Auth/actions";
import Headerauth from "../Headerauth/Headerauth";
import "./Login.scss";
import { DEVICE_ID } from "../../../Constants/ImgConstants";
import InputTextField from "../../BusinessProfile/TextField";
import {
  getCookiesDataInSubDomain,
  getDeviceFingerprints,
  validateEmail,
} from "../../../utils/helper";
import { useHistory } from "react-router-dom";
import { customSnackBar } from "../../../utils";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";
import CryptoJs from "crypto-js";
import Footer from "../Footer";

const Login = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState("");
  const [isValid, setValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const emailInputRef = useRef(null);
  const history = useHistory();
  const [redirectUrlDevice, setRedirectUrlDevice] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  // const [data, setData] = useState([]);
  // let history = useHistory();
  localStorage.setItem("url", redirectUrl);
  // localStorage.setItem("deviceId", redirectUrlDevice);



  console.log("authToken", redirectUrlDevice,redirectUrl)
  const decruptUrl = (str) => {
    return CryptoJs.AES.decrypt(str, "secret_key").toString(CryptoJs.enc.Utf8);
  };
  useEffect(() => {
    const token = localStorage.getItem("url");
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const redirectInfoParam = params.get("redirectInfo");

    const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
    try {
      const redirectObject = JSON.parse(decodedRedirectInfo);
      console.log(redirectObject,"redirectObjectredirectObject")
      const decryptedURL = decruptUrl(redirectObject.encryptedURL);
      console.log(decryptedURL,"redirectObjectredirectObject")
      localStorage.setItem("platFormId", redirectObject?.platformId);

      const decryptedURLDeviceID = redirectObject.deviceId;
      setRedirectUrl(decryptedURL);
      // setRedirectUrlDevice(decryptedURLDeviceID)
    } catch (e) {
      console.error("Error parsing redirectInfo:", e);
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValid(validateEmail(e.target.value));
  };
  
  // useEffect(() => {
  //   // Assuming getDeviceFingerprints returns a Promise, and deviceData contains `uniqueId`
  //   const fetchDeviceId = async () => {
  //     try {
  //       const deviceData = await getDeviceFingerprints();
      

     
  //       if (deviceData?.uniqueId) {
  //         localStorage.setItem("deviceId", deviceData.uniqueId);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching device ID:", error);
  //     }
  //   };

  //   fetchDeviceId();
  // }, []);
  const handleSubmit = async () => {
    // Verify ReCaptcha token before submitting the form
    await handleReCaptchaVerify();
    if (token === null) {
      customSnackBar("Token is not yet fetched");
    }
    // Check if the token is available
    else if (token || authToken) {
      dispatch(
        registerapi({
          email: email,
          countryId: 1,
          deviceId: DEVICE_ID,
          raptchaToken: token ? token : authToken,
          setIsLoading,
          redirectUrl,
        })
      );
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter" && isValid) {
      e.preventDefault(); // Prevent form submission
      handleSubmit(); // Automatically trigger the button click
    }
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha("submit");
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  const handleToken = (token) => {
    setIsLoading(true);
    setAuthToken(token);
    setIsLoading(false);
    return token;
  };
console.log(authToken,"authToken")
  return (
    <div className="login_height">
      {!authToken && <GoogleReCaptcha onVerify={handleToken} />}
      <div className="container mb-5 pb-5">
        <div className="container  paddings ">
          <div className="text-center alignment_vertically ">
            <img
              className="img-fluid pb-4"
              src="/images/plex-logo.svg"
              style={{ width: "151px" }}
            />
            {/* <img
              className="img-fluid img_plexar_width"
              src="../images/plexaar final 2 round-15 1.png"
              alt="no-data"
              style={{ width: "100px", height: "100px" }}
            />
            <div>
              <img
                className="img-fluid img_plexar_width pb-3"
                src="../images/plexaar text-22 1.png"
                alt="no-data"
                style={{ width: "111px", height: "46" }}
              />
            </div> */}
            <h3>
              <b>
                Welcome to <span className="plexar_font">Plexaar</span>
              </b>
            </h3>
            <p className="sub_heading pt-2 text-wrap">
              To keep connected with us please continue with your personal
              information by email and password.
            </p>
            <div className="col-md-12">
              <div className="p-2 pt-4 mt-4">
                {/* <InputTextField
                    id="email"
                    label="Email"
                    name="email"
                    placeholder="Enter Email"
                    size={"small"}
                    variant="filled"
                    value={email}
                    onChange={handleEmailChange}
                    type="email"
                    sx={{
                      // maxWidth: "300px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                  /> */}
                <InputTextField
                  id="email"
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                  variant="filled"
                  size="small"
                  type="email"
                  value={email}
                  autoFocus
                  onKeyDown={handleEnterKey}
                  inputRef={emailInputRef}
                  onChange={handleEmailChange}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton
                  //       onClick={toggle}
                  //       edge="end"
                  //       className="pe-3"

                  //       aria-label="toggle password visibility"
                  //     >
                  //       {isVisible ? (
                  //         <img
                  //           className="img-fluid img_plexar_width"
                  //           src="../images/eye_open.svg"
                  //           alt="no-data"
                  //           style={{ width: "20px", height: "20px" }}
                  //         />
                  //       ) : (
                  //         <img
                  //           className="img-fluid img_plexar_width"
                  //           src="../images/eye_open.svg"
                  //           alt="no-data"
                  //           style={{ width: "20px", height: "20px" }}
                  //         />
                  //       )}
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
              </div>
              <div className="col-md-12 pt-4">
                {
                  <button
                    className={`btn btn-primary pb-3 ${
                      !isValid ? "button_width_disabled" : "button_width"
                    } btn-lg`}
                    onClick={handleSubmit}
                    disabled={!isValid || isLoading || token === null}
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>Next</>
                    )}
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer history={history} />
    </div>
  );
};
export default Login;
