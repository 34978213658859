import React from 'react'

const Footer = ({history}) => {
  return (
    <div className="bottom_attach px-3 pt-2 center_copyright">
    <div className="show_privacy_policy">
      <p
        className="sub_heading2"
        role="button"
        onClick={() => history.push("/privacy")}
      >
        Privacy Policy
      </p>{" "}
      &nbsp; &nbsp; &nbsp;
      <p
        className="sub_heading2"
        role="button"
        onClick={() => history.push("/terms")}
      >
        Terms & Conditions
      </p>
    </div>
    <p className="sub_heading2">
      Copyright © 2023 Plexaar. All rights reserved.
    </p>
  </div>  )
}

export default Footer