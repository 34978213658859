import Login from "../Pages/Auth/Login";
import Password from "../Pages/Auth/Password/Password";
import AddNumber from "../Pages/Auth/Addnumber/Addnumber";
import DeleteAccount from "../Pages/Auth/DeleteUser/index";
import ResetPassword from "../Pages/Auth/Resetpassword/Resetpassword";
import CreatePassword from "../Pages/Auth/Createpassword/Createpassword";
import ChangePassword from "../Pages/Auth/Createpassword/Changepassword";
import ForgetPassword from "../Pages/Auth/Forgetpassword/Forgetpassword";
import ForgetPasswordUser from "../Pages/Auth/Forgetpassword/ForgetPasswordUser";
import EmailVerfication from "../Pages/Auth/Emailverification/Emailverification";
import VerifyPrimaryPhone from "../Pages/Auth/Mobileverification/Mobileverification";
import ProfileInformation from "../Pages/Auth/ProfileInformation/ProfileInformation";
import Terms from "../Pages/PublicPages/Terms";
import Privacy from "../Pages/PublicPages/Privacy";

import PageNotFound from "../Pages/PageNotFound";

const routes = [
  {
    path: "/",
    component: Login,
    ispublic: true,
    exact: true,
  },
  {
    path: "/delete_account",
    component: DeleteAccount,
    ispublic: true,
    exact: true,
  },
  {
    path: "/Password/:email",
    component: Password,
    ispublic: true,
    // exact: true,
  },
  {
    path: "/ForgetPassword/:email",
    component: ForgetPasswordUser,
    ispublic: true,
    // exact: true,
  },
  {
    path: "/Email-verification/:email",
    component: EmailVerfication,
    ispublic: true,
    // exact: true,
  },
  {
    path: "/Updated-Email-verification/:email",
    component: EmailVerfication,
    ispublic: true,
    // exact: true,
  },
  {
    path: "/Profile-information",
    component: ProfileInformation,
    ispublic: true,
    exact: true,
  },
  {
    path: "/create-password",
    component: CreatePassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/reset-user-password",
    component: ChangePassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/add-number",
    component: AddNumber,
    ispublic: true,
    exact: true,
  },
  {
    path: "/verify-number/:number",
    component: VerifyPrimaryPhone,
    ispublic: true,
    exact: true,
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    ispublic: true,
    exact: true,
  },
  {
    path: "/terms",
    component: Terms,
    ispublic: true,
    exact: true,
  },
  {
    path: "/privacy",
    component: Privacy,
    ispublic: true,
    exact: true,
  },
  {
    path: "*",
    component: PageNotFound,
    ispublic: false,
  },
];

export default routes;
