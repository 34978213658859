import axios from "axios";
import { logout } from "../Store/Auth/actions";
import { useDispatch } from "react-redux";
import {
  Logout,
  getCookiesData,
  getCookiesDataInSubDomain,
  removeAllCookies,
  removeAllLocalStorage,
  setCookiesData,
  setCookiesDataWithTimeInSubDomain,
} from "./helper";
import CryptoJS from "crypto-js";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const base64Key = "j6d7KKC+66unhDowhE6JJogaTpKrKBhnXTBO4YPBSnQ=";
const base64Iv = "KTZInClCMhcJNGbQj2rN6w==";
const encryptUserId = (userId) => {
  console.log(userId, "authenticationauthentication");

  const key = CryptoJS.enc.Base64.parse(base64Key);
  const iv = CryptoJS.enc.Base64.parse(base64Iv);
  console.log(key, iv, "authenticationauthentication");

  const encrypted = CryptoJS.AES.encrypt(userId, key, {
    iv: iv,
  });
  console.log(encrypted, "authenticationauthentication");

  return encrypted.toString(); 
};

const encryptDeviceId = (userId) => {
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const iv = CryptoJS.enc.Base64.parse(base64Iv);
  const encrypted = CryptoJS.AES.encrypt(userId, key, {
    iv: iv,
  });

  return encrypted.toString();
};

instance.interceptors.request.use(
  async (config) => {
    try {
      // Check if the URL contains "pv" and attach authorization token accordingly
      if (config.url.includes("/pv/")) {
        const token = "token";
        const authentication = getCookiesDataInSubDomain("auth");
        const encryptedUserId = encryptUserId(
          JSON.stringify(authentication.userId)
        );
const encryptdDeviceId=encryptUserId(localStorage.getItem("deviceId"))

        console.log(encryptedUserId, "authenticationauthentication");

        if (authentication?.token) {
          config.headers.authorization = `Bearer ${authentication?.token}`;
          config.headers["userId"] = encryptedUserId;
        config.headers["deviceId"] = encryptdDeviceId;
          
        }
      }else{
const encryptdDeviceId=encryptDeviceId(localStorage.getItem("deviceId"))

        config.headers["deviceId"] = encryptdDeviceId;

      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 409) {
      // window.location.reload()
    }

    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Set the _retry flag to true to avoid infinite loop
      try {
        const authentication = getCookiesDataInSubDomain("auth");

        const base64EncodedToken = btoa(authentication.refreshToken);

        const resp = await instance.get(
          `signup_svc/pb/users/getnewRefreshToken?tokenModel=${base64EncodedToken}`
        );

        if (
          resp.data.code === 0 &&
          resp.data &&
          resp.data.result.jwtRefreshToken
        ) {
          const accessToken = resp?.data?.result.jwtToken;
          localStorage.setItem("token", accessToken);
          localStorage.setItem(
            "refreshToken",
            resp?.data?.result.jwtRefreshToken
          );

          setCookiesDataWithTimeInSubDomain(
            "auth",
            JSON.stringify({
              token: accessToken,
              refreshToken: resp?.data?.result.jwtRefreshToken,
            })
          );
          instance.defaults.headers.common.authorization = `Bearer ${accessToken}`;
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          // Retry the original request
          return instance(originalRequest);
        } else if (resp.data.code === -1) {
          // window.location.reload()
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    } else return Promise.reject(error);
  }
);

export default instance;
