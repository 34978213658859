import React, { useCallback, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resendprimaryotpmobile,
  verifymobileotp,
} from "../../../Store/Auth/actions";
import "../Login/Login.scss";
import OtpInput from "react-otp-input";
import { useEffect } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getCookiesDataInSubDomain } from "../../../utils/helper";

export default function Mobilrverfication(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [usernumber, setUsernumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isLoadingresend, setIsLoadingresend] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [resendCount, setResendCount] = useState(0); // Track resend button clicks

  const [time, setTime] = useState(110); // Initial time in seconds
  const history = useHistory();
  const userphone = useSelector(
    (state) => state.Auth?.mobilenumberreducer?.mobileNumber
  );
  const userid = useSelector((state) => state.Auth?.registersuccessId);
  const users = localStorage.getItem('user')
  useEffect(() => {
    setUsernumber(userphone);
  }, [userphone]);

  const dispatch = useDispatch();
  let { number } = useParams();
  const otpInputRef = useRef(null);

  //////////////////////
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha('submit');
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  useEffect(() => {
    // otpInputRef.current.focus()
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            setIsActive(false);
            return 60; // Reset the time to 1 minute 50 seconds
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [isActive]);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const auth = getCookiesDataInSubDomain("auth");

  /////////////////////
  const handleSubmit = () => {
    dispatch(
      verifymobileotp({
        otpCode: otp,
        phone: userphone,
        userId: auth && auth?.user && auth?.user?.id,
        user: users?.result?.users,
        setIsLoading,
        setOtp
      })
    );
  };

  const resetOpt = () => {
    setOtp(""); // Reset 'opt' to an empty value or any other desired initial value
  };
  const resendotponcallprimarymobile = async () => {
    await handleReCaptchaVerify();
    if (token||authToken) {
      dispatch(
        resendprimaryotpmobile({
          id: auth && auth?.user && auth?.user?.id,
          type: 1,
          setIsActive,
          setIsLoadingresend,
          usernumber,
          raptchaToken: authToken ? authToken : token,


        })
      );
      setResendCount((prevCount) => prevCount + 1); // Increment resend count
      if (resendCount === 0) {
        setTime(60); // 1 minute for the first resend
      } else if (resendCount === 1) {
        setTime(300); // 5 minutes for the second resend
      } else if (resendCount >= 2) {
        setTime(3600); // 1 hour for subsequent resends
      }
      if (!isActive) {
        setTime(110); // Reset the time to 1 minute 50 seconds
      }
    }
  };

  const validateOTP = (otp) => {
    const otpRegex = /^[0-9]*$/;
    return otpRegex.test(otp);
  };
  const handleToken = useCallback((token) => {
    setAuthToken(token);
    return token;
  }, []);

  const handleEnterKey = (e) => {
    if (e.key === "Enter" && otp.length === 6 && validateOTP(otp)) {
      e.preventDefault();
      handleSubmit();
    }
  };
  return (
    <div className="login_height">
      {!authToken && <GoogleReCaptcha onVerify={handleToken} />}
      <div className="container mb-5 pb-5">
        <div className="container paddings">
          <div className="text-center alignment_vertically">
            {/* <div className="pb-3">
                <img
                  className="img-fluid img_plexar_width"
                  src="../images/plexaar final 2 round-15 1.png"
                  alt="no-data"
                  style={{ width: "100px", height: "100px" }}
                />
                <div>
                  <img
                    className="img-fluid img_plexar_width"
                    src="../images/plexaar text-22 1.png"
                    alt="no-data"
                    style={{ width: "111px", height: "46" }}
                  />
                </div>
              </div> */}
            <img className="img-fluid pb-4" src="/images/plex-logo.svg" style={{ width: "151px" }} />
            <h3>
              <b>
                Mobile <span className="plexar_font">Verification</span>
              </b>
            </h3>
            <p className="sub_heading mt-4 mb-0">
              Enter the code that was sent to
            </p>
            <p className="sub_heading1 pt-1">+{usernumber}</p>
            <div>
              <div
                className="ps-4 pe-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  ref={otpInputRef}
                  // className="input_style"
                  numInputs={6}
                  onKeyDown={handleEnterKey}
                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "3em",
                        height: "3em",
                        textAlign: "center",
                      }}
                    />
                  )}
                />
              </div>
              <div className="px-0 text-center">
                <p className="mb-1 pt-3 having_problrm">
                  Having problem?
                </p>
                {isActive && authToken ? (
                  <p className="mb-0 resend_button_disabled">
                    Resend Code
                  </p>
                ) : (
                  <p
                    className="mb-0 resend_button"
                    // onClick={resendotponcallprimaryemail}
                    onClick={() => {
                      resetOpt();
                      resendotponcallprimarymobile(); // Call your function
                    }}
                  >
                    Resend Code
                  </p>
                )}
                {isLoadingresend ? (
                  <div
                    className="spinner-border spinner-sm text-primary"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}

                <p className=" having_problrm">
                  {" "}
                  {isActive ? <div>{formatTime()}</div> : null}{" "}
                </p>
              </div>
              <div className="px-0 ps-4 pe-4">
                {otp.length === 6 && validateOTP(otp) ? (
                  <button
                    className="btn btn-primary button_width btn-lg"
                    // onClick={() => history.push(`/create-password`)}
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <div
                        className="spinner-border text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>Verify Now</>
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary button_width_disabled button_width btn-lg"
                    // onClick={() => history.push(`/create-password`)}
                    disabled={authToken ? false : true}
                  >
                    Verify Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_attach px-3 pt-2 center_copyright">
        <div className="show_privacy_policy">
          <p className="sub_heading2" role="button" onClick={() => history.push("/privacy")}>Privacy Policy</p> &nbsp; &nbsp; &nbsp;
          <p className="sub_heading2" role="button" onClick={() => history.push("/terms")}>Terms & Conditions</p>
        </div>
        <p className="sub_heading2">
          Copyright © 2023 Plexaar. All rights reserved.
        </p>
      </div>
    </div>
  );
}

/* <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4 pt-5">
            <div className=" ">
              <div className="text-center ">
                <div className="alignment_vertically">
                  <div className="pb-3">
                    <img
                      className="img-fluid img_plexar_width"
                      src="../images/plexaar final 2 round-15 1.png"
                      alt="no-data"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div>
                      <img
                        className="img-fluid img_plexar_width"
                        src="../images/plexaar text-22 1.png"
                        alt="no-data"
                        style={{ width: "111px", height: "46" }}
                      />
                    </div>
                  </div>
                  <h4>
                    <b>
                      Email <span className="plexar_font">Verification</span>
                    </b>
                  </h4>
                  <p className="sub_heading mb-0">
                    Entered the code that was sent to
                  </p>
                  <p className="sub_heading1 pt-2">{number}</p>
                  <div className="col-md-12">
                    <div className="">
                      <div className="ps-5 ms-3 text-center">
                        <OtpInput
                          value={otp}
                          onChange={(otp) => setOtp(otp)}
                          className="input_style"
                          numInputs={6}
                          renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{ width: "2em", textAlign: "center" }}
                            />
                          )}
                        />
                         <div className="text-left">
                       { validateOTP(otp) ? null :<h6 className="warning_text">Only enter numbers will not accept alphabets and !@#$%^</h6>}
                       </div>
                      </div>
                      <div className="col-md-12 px-0">
                        <div className="col-md-12 text-center">
                          <p className="mb-1 pt-3 having_problrm">
                            Having problem?
                          </p>
                          {isActive ? (
                            <p className="mb-0 resend_button_disabled">
                              Resend Code
                            </p>
                          ) : (
                            <p
                              className="mb-0 resend_button"
                              // onClick={resendotponcallprimaryemail}
                            >
                              Resend Code
                            </p>
                          )}
                          {isLoadingresend ? (
                            <div
                              className="spinner-border spinner-sm text-primary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}

                          <p className=" having_problrm">
                            {" "}
                            {isActive ? <div>{formatTime()}</div> : null}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 px-0 ">
                        {otp.length === 6 && validateOTP(otp) ? (
                          <button
                            className="btn btn-primary button_width btn-lg"
                            // onClick={() => history.push(`/create-password`)}
                            onClick={handleSubmit}
                          >
                            {isLoading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <>Verify Now</>
                            )}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary button_width_disabled button_width btn-lg"
                            // onClick={() => history.push(`/create-password`)}
                            disabled
                          >
                            Verify Now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div> */

// <div className="col-sm-12 px-0 height_page">
//   <div className="col-sm-12 padding_top_bottom_equal">
//     <Headerauth />
//   </div>
//   <div className="col-md-12 ">
//     <div className="col-md-12 px-5">
//       <div className="row">
//         <div className="col-md-3 background_plexar text-center  d-flex align-items-center  justify-content-center">
//           <img
//             className="img-fluid img_plexar_width"
//             src="../images/plexar/plexar.png"
//           />
//         </div>
//         <div className="col-md-9 border_welcome_to_plexar text-center ">
//           <div className="col-md-12 alignment_vertically">
//             <h2>
//               <b>
//                 Mobile <span className="plexar_font">Verification</span>
//               </b>
//             </h2>

//             <p className="sub_heading mb-0">
//               Entered the code that was sent to
//             </p>
//             <p className="sub_heading mb-0">
//               <b>{usernumber}</b>{" "}
//             </p>
//             <p className="sub_heading1">{number}</p>
//             <div className="col-md-12">
//               <div className="row">
//                 <div className="col-md-4"></div>
//                 <div className="col-md-4 pt-3">
//                   <OtpInput
//                    value={otp}
//                    onChange={(otp) => setOtp(otp)}
//                     className="input_style"
//                     numInputs={6}
//                     renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
//                     renderInput={(props) => <input {...props} />}
//                   />
//                   <div className="text-left">
//                   { validateOTP(otp) ? null :<h6 className="warning_text">Only enter numbers will not accept alphabets and !@#$%^</h6>}
//                   </div>
//                   <div className="col-md-12 text-center">
//                     <p className="mb-1 pt-3 having_problrm">
//                       Having problem?
//                     </p>
//                     {isActive?  <p className="mb-0 resend_button_disabled"  >Resend Code</p> :  <p className="mb-0 resend_button" onClick={resendotponcallprimarymobile} >Resend Code</p> }
//                     {isLoadingresend ? (
//                           <div
//                             className="spinner-border spinner-sm text-primary"
//                             role="status"
//                           >
//                             <span className="sr-only">Loading...</span>
//                           </div>
//                         ) : (
//                          null
//                         )}

//                    <p className=" having_problrm"> {isActive ? <div>{formatTime()}</div> : null } </p>
//                   </div>
//                   <div className="col-md-12 px-0 ">

//                     {/* <button
//                       onClick={handleSubmit}
//                       className="btn btn-primary button_width btn-lg"
//                     >
//                     {isLoading ? (
//                           <div
//                             className="spinner-border text-light"
//                             role="status"
//                           >
//                             <span className="sr-only">Loading...</span>
//                           </div>
//                         ) : (
//                           <>Verify Now</>
//                         )}
//                     </button> */}
//                       {otp.length === 6 && validateOTP(otp)  ? (
//                       <button
//                         className="btn btn-primary button_width btn-lg"
//                         // onClick={() => history.push(`/create-password`)}
//                         onClick={handleSubmit}
//                       >
//                         {isLoading ? (
//                           <div
//                             className="spinner-border text-light"
//                             role="status"
//                           >
//                             <span className="sr-only">Loading...</span>
//                           </div>
//                         ) : (
//                           <>Verify Now</>
//                         )}
//                       </button>
//                     ) : (
//                       <button
//                         className="btn btn-primary button_width_disabled button_width btn-lg"
//                         // onClick={() => history.push(`/create-password`)}
//                         disabled
//                       >
//                         Verify Now
//                       </button>
//                     )}
//                   </div>
//                 </div>
//                 <div className="col-md-4"></div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-12 bottom_attach ">
//             <hr />
//             <p className="sub_heading">
//               Terms & Conditions. Privacy Policy. Copyright. Cookies Policy.
//               Help
//             </p>
//             <p className="sub_heading">&copy; Selteq Ltd.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
