import React, { useEffect, useRef, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resendnewprimaryemailotp,
  sendotopemail,
} from "../../../Store/Auth/actions";
import "../Login/Login.scss";
import OtpInput from "react-otp-input";
import { useParams } from "react-router-dom";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { customSnackBar } from "../../../utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Password(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState(null);
  const location = useLocation();
  const initialTime = localStorage.getItem("timer") || 110;
  const [time, setTime] = useState(parseInt(initialTime));
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingresend, setIsLoadingresend] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [otp, setOtp] = useState("");
  const [resendCount, setResendCount] = useState(0); // Track resend button clicks
  console.log(location, "locationlocationlocation");
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.Auth?.registersuccessId);

  const accountNumber = useSelector(
    (state) => state.Auth?.forgetPassword?.result?.accountNumber
  );
  let { email } = useParams();
  const history = useHistory();
  const otpInputRef = useRef(null); // Create a ref for the OTP input
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha("submit");
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    // otpInputRef.current.focus()
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            setIsActive(false);
            return 60; // Reset the time to 1 minute
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [isActive]);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleSubmit = () => {
    dispatch(
      sendotopemail({
        otpCode: otp,
        email: email,
        userId: userid,
        setIsLoading,
        setOtp,
        accountNumber,
        flag: location.state,
        location: location.pathname,
      })
    );
  };

  const resetOpt = () => {
    setOtp(""); // Reset 'opt' to an empty value or any other desired initial value
  };
  const resendotponcallprimaryemail = async () => {
    await handleReCaptchaVerify();
    if (token||authToken) {

    dispatch(
      resendnewprimaryemailotp({
        // id: userid,
        email: email,

        type: 1,
        raptchaToken: authToken ? authToken : token,

        setIsActive,
        setIsLoadingresend,
      })
    );

    setResendCount((prevCount) => prevCount + 1); // Increment resend count
    // Adjust time based on resend count
    if (resendCount === 0) {
      setTime(60); // 1 minute for the first resend
    } else if (resendCount === 1) {
      setTime(300); // 5 minutes for the second resend
    } else if (resendCount >= 2) {
      setTime(3600); // 1 hour for subsequent resends
    }
    // if (!isActive) {
    //   setTime(110); // Reset the time to 1 minute 50 seconds
    // }
  };
}
  const handleEnterKey = (e) => {
    if (e.key === "Enter" && otp.length === 6 && validateOTP(otp)) {
      e.preventDefault();
      handleSubmit();
    }
  };
  const validateOTP = (otp) => {
    const otpRegex = /^[0-9]*$/;
    return otpRegex.test(otp);
  };

  useEffect(() => {
    localStorage.setItem("timer", time);
  }, [time]);

  const handleToken = (token) => {
    setIsLoading(true);
    setAuthToken(token);
    setIsLoading(false);
    return token;
  };
  return (
    <div className="login_height">
      {!authToken && <GoogleReCaptcha onVerify={handleToken} />}
      <div className="container mb-5 pb-5">
        <div className="container paddings">
          <div className="text-center alignment_vertically">
            {/* <div className="pb-3"> */}
              <img className="img-fluid pb-4" src="/images/plex-logo.svg" style={{ width: "151px" }} />
              {/* <img
                className="img-fluid img_plexar_width"
                src="../images/plexaar final 2 round-15 1.png"
                alt="no-data"
                style={{ width: "100px", height: "100px" }}
              />
              <div>
                <img
                  className="img-fluid img_plexar_width"
                  src="../images/plexaar text-22 1.png"
                  alt="no-data"
                  style={{ width: "111px", height: "46" }}
                />
              </div> */}
            {/* </div> */}
            <h3 className="">
              <b>
                Email <span className="plexar_font pt-5">Verification</span>
              </b>
            </h3>
            <p className="sub_heading mt-3 mb-3">
              <span style={{ display: "inline" }}>
                We sent verification code to{" "}
                <b className="text-black">{email}</b>.
              </span>
              <br />
              Please check your inbox and enter the code below.
            </p>
            <div className="pt-4">
              <div
                className="flex-wrap"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={6}
                  ref={otpInputRef}
                  onKeyDown={handleEnterKey} // Trigger OTP verification on Enter key
                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "3em",
                        height: "3em",
                        textAlign: "center",
                      }}
                    />
                  )}
                />
              </div>
              <div className="px-0">
                <div className="text-center">
                  <p className="mb-1 pt-4 having_problrm">Having problem?</p>
                  {isActive ? (
                    <p className="mb-0 resend_button_disabled">Resend Code</p>
                  ) : (
                    <p
                      className="mb-0 resend_button"
                      onClick={() => {
                        if (token) {
                          resetOpt();
                          resendotponcallprimaryemail(); // Call your function
                        } else {
                          customSnackBar("Google Recaptcha not yet Fetch");
                        }
                      }}
                    >
                      Resend Code
                    </p>
                  )}
                  {isLoadingresend ? (
                    <div
                      className="spinner-border spinner-sm text-primary"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}

                  <p className=" having_problrm">
                    {" "}
                    {isActive ? <div>{formatTime()}</div> : null}{" "}
                  </p>
                </div>
              </div>
              <div className="px-0 pb-5 ps-4 pe-4">
                {otp.length === 6 && validateOTP(otp) ? (
                  <button
                    className="btn btn-primary button_width btn-lg"
                    // onClick={() => history.push(`/create-password`)}
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>Verify Now</>
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary button_width_disabled button_width btn-lg"
                    // onClick={() => history.push(`/create-password`)}
                    disabled
                  >
                    Verify Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_attach px-3 pt-2 center_copyright">
        <div className="show_privacy_policy">
          <p
            className="sub_heading2"
            role="button"
            onClick={() => history.push("/privacy")}
          >
            Privacy Policy
          </p>{" "}
          &nbsp; &nbsp; &nbsp;
          <p
            className="sub_heading2"
            role="button"
            onClick={() => history.push("/terms")}
          >
            Terms & Conditions
          </p>
        </div>
        <p className="sub_heading2">
          Copyright © 2023 Plexaar. All rights reserved.
        </p>
      </div>
    </div>
  );
}
