import React from "react";
// import SideBarHeader from "../../../SideNavBar/SideBarHeader";
import "./Terms.scss";
import { useHistory } from "react-router-dom";

function Terms() {
  let history = useHistory();

  return (
    <div className="col-sm-12 p-0" style={{ backgroundColor: "#F8F8FA" }}>
      {/* <SideBarHeader /> */}
      <div className="col-sm-12 padding-top-tc">
        <div className="row">
          <div className="col-sm-3 text-center d-none d-md-block">
            <button
              onClick={() => history.goBack()}
              className="btn btn-light btn-lg rounded-circle button-outline-tc"
            >
              <i className="fas fa-arrow-left"></i>
            </button>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-6">
                <h5 style={{ textDecoration: "underline" }}>
                  <b>
                    TERMS OF USE & THE INDEPENDENT SERVICE PROVIDER AGREEMENT
                  </b>
                </h5>
              </div>
              <div className="col-sm-6 text-right m-auto">
                <h6>
                  Last modified: <b>November 2, 2023</b>
                </h6>
              </div>
              <div className="col-sm-12 py-4 background_tc mt-4">
                <h6>
                  <b>ACCEPTANCE OF TERMS OF USE OF PLEXAAR APP</b>
                </h6>
                <p>
                  Please carefully read the following Terms of Use (“Terms”)
                  before using the Plexaar application. By accessing and using
                  this application, the User (“you”) acknowledge that you have
                  read and understood and agree to be bound by these Terms,
                  which form an agreement that is as effective as if you had
                  signed it. If it any time you do not agree to these Terms,
                  your permission to access or use Plexaar app or any of its
                  content is automatically and immediately revoked. Your access
                  to, use of and browsing of the site and its contents are
                  subject to all Terms of Use contained herein, the Privacy
                  Policy and all applicable laws and regulations.
                </p>
                <p className="text-center">
                  <b>AND</b>
                </p>
                <p>
                  <b>Name, (“The Independent Service Provider”)</b> [hereinafter
                  referred to as "<b>Party B</b>", which expression shall where
                  the context so permits, mean and include its administrator(s),
                  the nominee(s), the successor(s)-in-interest and permitted
                  assignee(s)]
                  <br />
                  <br />
                  <b>[Parties A and B</b> shall, hereinafter, be collectively
                  referred to as the "<b>Parties</b>"<b>.]</b>
                </p>
                <p className="text-center">
                  <b>WHEREAS</b>
                </p>
                <ol style={{ listStyleType: "lower-roman" }}>
                  <li>
                    Find an Expert Private Limited is in the business of
                    Software Development and it is a platform from which the
                    services of the Independent Service Providers could be
                    hired.{" "}
                  </li>
                  <li>
                    The Company wishes to work together with the Independent
                    Service Providers to bring them together under one roof and
                    make them accessible for the General Public.
                  </li>
                  <li>
                    Party A (Plexaar) is desirous and Party B (service provider)
                    agreed to provide services to Party A on the terms and
                    conditions contained in this Agreement
                  </li>
                </ol>
                <h6>
                  <b>
                    NOW, THEREFORE, IT IS HEREBY MUTUALLY AGREED BY AND BETWEEN
                    THE PARTIES HERETO AS FOLLOWS:
                  </b>
                </h6>
                <ol>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 1 - DEFINITIONS AND INTERPRETATION</b>
                    </h6>
                    <ol>
                      <li>
                        In this Agreement, as hereinafter defined, unless a
                        different intention appears from the context, the
                        following terms shall have the meaning hereby assigned
                        to them:
                        <ol>
                          <li>
                            <b>“Agreement”</b> shall mean this agreement between
                            the Parties alongwith its Annexures.{" "}
                          </li>
                          <li>
                            <b>“Annexure(s)”</b>shall mean and include the
                            annexures(s) regarding documents of the the
                            Independent Service Provider and the Compmay to be
                            subsequently attached with this Agreement.
                          </li>
                          <li>
                            <b>“Books of Accounts”</b> shall mean the document
                            wherein all the financial information/transactions
                            of the Project is recorded and maintained.{" "}
                          </li>
                          <li>
                            <b>“Consent”</b> and its grammatical variations
                            shall mean approval in writing of the Parties after
                            its review confirming compliance with Agreement
                            requirements and it being understood and agreed that
                            no Consent shall be unreasonably held by the Parties
                            or each Party.
                          </li>
                          <li>
                            <b>“Document ”</b> shall mean any documentation
                            required by the Company and ID card, B-form, Driving
                            License, Domicile, Proof of Residence, etc.
                          </li>
                          <li>
                            <b>“Effective Date”</b> shall mean the date on which
                            the Agreement is signed and executed between the
                            Parties.
                          </li>
                          <li>
                            <b>“Independent Service Provider”</b> shall mean
                            experts hired by the Company to render services for
                            the purposes of the App, which, inter-alia, may
                            include Electrician, Plumber, Cleaner, Makeup
                            Artist, Gardener, Tailor, Tutor, Cook, Maid,
                            Mechanic etc.{" "}
                          </li>
                          <li>
                            <b>“Notice”</b> shall mean any Notice in writing
                            given under the Agreement and delivered.
                          </li>
                          <li>
                            <b>“Profit”</b> shall mean and include all net or
                            gross income derived from the Project through any
                            lawful means.
                          </li>
                          <li>
                            <b>“Schedule of Works”</b> shall mean and include a
                            Schedule of Works selected by the user of the App
                            for the Independent Service Provider.{" "}
                          </li>
                          <li>
                            <b>“Task”</b> shall mean a piece of work being
                            assigned to the person after he accpets to perform
                            the same via PLEXAAR APP.{" "}
                          </li>
                          <li>
                            <b>“Training ”</b> shall mean and include every
                            person who seeks registration with the Plexaar App
                            will be duty-bound to undergo training from time to
                            time as specified by the Company.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 2 – NATURE AND DURATION OF THE AGREEMENT</b>
                    </h6>
                    <ol>
                      <li>
                        During the subsistence of the Agreement, the Independent
                        Service Provider shall be responsible and liable to
                        complete the task he or she has accepted to perform the
                        App.
                      </li>
                      <li>
                        Party B shall ensure that every given task is executed
                        within stipulated time and as requested by the
                        User/Client.
                      </li>
                      <li>
                        The Company will have the liberty to select anyone they
                        like and the Independent Service Provider will have no
                        legitimate right to be registered with the Company.
                      </li>
                      <li>
                        The duration of the contract will be valid as long as
                        the Indpendent Service Provider will be on board.
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 3 – PLACE OF BUSINESS</b>
                    </h6>
                    <ol>
                      <li>
                      The place of business for the purposes of this Agreement shall be entire UK or any other country where the Plexaar is operational.
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 4 – SCOPE AND RELATIONSHIP OF PARTY A AND B</b>
                    </h6>
                    <ol>
                      <li>
                      The Service Provider will provide the services to the Company as an independent Service Provider and not as an employee.  
                      </li>
                      <li>
                      The Service Provider agrees that the Company shall have no liability or responsibility for the withholding, collection or payment of any taxes.
                      </li>
                      <li>
                      The Service Provider agrees that as an independent Service Provider, he or she will not be qualified to participate in or to receive any employee benefits that the Company may extend to its employees. 
                      </li>
                      <li>
                      The Service Provider is free to provide services to other clients, so long as such other clients are not in competition with the Company and so long as there is no interference with the Service Provider’s contractual obligations to the Company.
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 5 – THE APP PLATFORM</b>
                    </h6>
                    <ol>
                      <li>
                      The Service Provider has no authority and will not exercise or hold itself out as having any authority to enter into or conclude any contract or to undertake any commitment or obligation for, in the name of or on behalf of the Company.  
                      </li>
                      <li>
                      It is hereby agreed between the Parties, if the Service Provider undertakes any commitment whatsoever will be held liable for criminal breach of trust,moreover, the Company will be at liberty to iniate legal proceedings against the Service Provider. 
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 6 – TERMINATION </b>
                    </h6>
                    <ol>
                      <li>
                      The Service Provider agrees that the Company may terminate this Agreement at any time without notice or any further payment if the Service Provider is in breach of any of the terms of this Agreement.
                      </li>
                      <li>
                      The Company may terminate this Agreement at any time at its sole discretion, upon providing to the Service Provider <b>30</b> days calendar days advance written notice. 
                      </li>
                      <li>
                      The Service Provider may terminate this Agreement at any time at its sole discretion upon providing to the Company <b>30</b> days calendar days advance written notice. 
                      </li>
                      <li>
                      The Agreement will be terminated if the Service Provider indulges in any activity which constitutes harassment or misconduct. Furthermore, the Agreement can be terminated if any criminal case is registered against the service provider.
                      </li>
                      <li>
                      The Agreement will also be terminated in case Service Provide during his work/shift and at customers premises, misbehaves, gets physical with the customer, or gets involved in any other activity which institutes as misconduct, the contract will be terminated immediately if the Service Provider is found guilty. The company reserves the right to initiate legal proceedings against the Service Provider. 
                      </li>
                      <li>
                      In addition to the preceding it is further agreed that if Service Provider deals with the client directly, in that case, his contract will be terminated immediately.
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 7 – LANGUAGE OF THE AGREEMENT</b>
                    </h6>
                    <ol>
                      <li>
                      The governing language of the Agreement shall be English. The definitions set forth in the Agreement shall be final in the use and interpretation of the terms of the Agreement. 
                      </li>
                      <li>
                      All Notices, correspondence, information, literature, data, manuals, and documents required under the Agreement shall be in the English Language including all communication between the Parties. 
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 8 – PAYMENT </b>
                    </h6>
                    <ol>
                      <li>
                      In consideration for the services performed by the Independent Service Provider, the the payment will be made by the Client/Customer upon completion of the work to be performed. 
                      </li>
                      <li>
                      If the Service Provider is a Tutor, Security Guard, or anyone whose work is not task-based. The payment by the Client/Customer can be monthly or weekly depending on the nature of the work.  
                      </li>
                      <li>
                      It is hereby agreed by the Parties that the payment will be made as per the mutual agreement between the Company and the Independent Service Provider, and depending on the nature of the job. The usual Breakup ratio of the payment will be 80:20. Service Provider will get 80 and the Companys due share will 20, but company reserves the right to change the ratio later on depending upon the nature of job. 
                      </li>
                      <li>
                      Any payment received for any task by the Company via credit or debit, the share of the Service Provider will be reverted on 10th of every month.   
                      </li>
                      <li>
                      The Payment received by the Service Provider on Cash on the Delivery basis, or after the completion of work task. The Service Provider will be duty-bound to revert the company's share as per mutual agreement on 10th of every month. 
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 9 - GOVERNING LAW</b>
                    </h6>
                    <ol>
                      <li>
                      This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of the United Kingdom. 
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 10 – DISCLAIMER</b>
                    </h6>
                    <ol>
                      <li>
                      This Agreement shall not be deemed to constitute either party to be the agent of the other. Duties performed by Service Provider will solely be as an Independent Service Provider. Any accident whatsoever materializes during the work, the Company shall not be held liable for the same.  
                      </li>
                      <li>
                      The Service Provider shall be responsible for his or her own safety during the work.
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 11 – SEVERANCE </b>
                    </h6>
                    <ol>
                      <li>
                      In the event any provision of this Agreement (or portion thereof) is determined by a court of competent jurisdiction to be invalid, illegal, or otherwise unenforceable, such provision shall be deemed to have been deleted from this Agreement, while the remainder of this Agreement shall remain in full force and effect according to its terms.  
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 12 – REIMBURSEMENT OF EXPENSES</b>
                    </h6>
                    <ol>
                      <li>
                      The Service Provider will not be reimbursed any expense incurred in connection with providing the services to the clients  
                      </li>
                      <li>
                      The Service Provider will not be paid any traveling expenses whatsoever in connection with providing services to the clients.
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 13 – ASSIGNMENT </b>
                    </h6>
                    <ol>
                      <li>
                      The Service Provider will not voluntarily, or by operation of law, assign or otherwise transfer its obligations under this Agreement without the prior consent of the client. 
                      </li>
                    </ol>
                  </li>
                  <br/>
                  <li
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <h6 style={{ textDecoration: "underline" }}>
                      <b>ARTICLE 14 – CHANGES IN TERMS AND CONDITIONS</b>
                    </h6>
                    <ol>
                      <li>
                      The Company will have the discretion to change the terms and conditions of the contract subject to a prior notice to the Service Provider.  
                      </li>
                    </ol>
                  </li>
                </ol>
                <p>IN WITNESS WHEREOF both the parties have set their hands on this Independent Service Provider Agreement in the presence of the witnesses after understanding every clause of this agreement with their free will.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3"></div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
