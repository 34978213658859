import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signinuser } from "../../../Store/Auth/actions";
import Headerauth from "../Headerauth/Headerauth";
import "../Login/Login.scss";
import InputTextField from "../../BusinessProfile/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { customSnackBar } from "../../../utils";
import CryptoJs from "crypto-js";

const Password = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [password, setpassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  // let history = useHistory();
  let { email } = useParams();
  let business = useSelector((state) => state.Business?.data);
  const passwordRef = useRef(null);
  const toggle = () => {
    setVisible(!isVisible);
  };

  const handleNumberChange = (e) => {
    setpassword(e.target.value);
  };
  const handleForgetPassword = () => {
    if (email === "") {
      customSnackBar("Please Enter Email");
    } else {
      history.push(`/ForgetPassword/${email}`);
    }
  };

  function encryptURL(currentURL) {
    return CryptoJs.AES.encrypt(currentURL, "secret_key").toString();
  }

  // useEffect(() => {
  //   history.push(redirectURL);
  // }, []);

  const customEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
      return "%" + c.charCodeAt(0).toString(16);
    });
  };
  const [redirectUrl, setRedirectUrl] = useState("");
  // const [data, setData] = useState([]);
  // let history = useHistory();

  const decruptUrl = (str) => {
    return CryptoJs.AES.decrypt(str, "secret_key").toString(CryptoJs.enc.Utf8);
  };

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);

  //   const redirectInfoParam = params.get("redirectInfo");
  //   const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
  //   try {
  //     const redirectObject = JSON.parse(decodedRedirectInfo);
  //     const decryptedURL = decruptUrl(redirectObject.encryptedURL);
  //     setRedirectUrl(decryptedURL);
  //   } catch (e) {
  //     console.error("Error parsing redirectInfo:", e);
  //   }
  // }, []);

  const handleSubmit = () => {
    //   const currentURL = window.location.href;
    // const encryptedURL = encryptURL(currentURL);
    // const redirectObject = {
    //   encryptedURL: encryptedURL,
    //   targetURL: 'http://localhost:3001/'
    // };

    // const redirectURL = `http://localhost:3001/?redirectInfo=${encodeURIComponent(JSON.stringify(redirectObject))}`;
    // window.location.href = redirectURL;
    // history.push({
    //   pathname: "/",
    //   search: `?currentUrl=${customEncodeURIComponent("https://calendex-staging.plexaar.com/")}`
    // });
    dispatch(
      signinuser({
        email: email,
        password: password,
        setIsLoading,
        business,
        rememberMe,
        setpassword,
        redirectUrl,
      })
    );
    // history.push(`/password/${email}`);
  };
  const handleEmailEnterPress = () => {
    passwordRef.current.focus(); // Focus on the password input field
  };

  useEffect(() => {
    passwordRef.current.focus();
  }, []);
  const handlePasswordEnterPress = () => {
    handleSubmit(); // Submit the form
  };
  return (
    <div className="login_height">
      <div className="container mb-5 pb-5">
        <div className="container paddings">
          <div className="text-center alignment_vertically">
            <img className="img-fluid pb-4" src="/images/plex-logo.svg" style={{ width: "151px" }} />
            {/* <img
              className="img-fluid img_plexar_width"
              src="/images/plexaar final 2 round-15 1.png"
              alt="no-data"
              style={{ width: "100px", height: "100px" }}
            />
            <div>
              <img
                className="img-fluid img_plexar_width pb-3"
                src="/images/plexaar text-22 1.png"
                alt="no-data"
                style={{ width: "111px", height: "46" }}
              />
            </div> */}
            <h4>
              <b>
                Welcome to <span className="plexar_font">Plexaar</span>
              </b>
            </h4>
            <p className="sub_heading">
              To keep connected with us please continue with your personal
              information by email and password.
            </p>
            <div className="p-2">
              {/* <InputTextField
                  id="email"
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                  size={"small"}
                  variant="filled"
                  value={email}
                  onChange={handleNumberChange}
                  autoFocused
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                /> */}

              <InputTextField
                id="email"
                label="Email"
                name="email"
                placeholder="Enter Email"
                variant="filled"
                type="email"
                size="small"
                value={email}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleEmailEnterPress();
                  }
                }}
                onChange={handleNumberChange}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
              />
            </div>
            <div className="px-2 input-group mt-2">
              <InputTextField
                id="password"
                label="Password"
                name="password"
                placeholder="Enter Password"
                size="small"
                variant="filled"
                type={isVisible ? "text" : "password"}
                // value={confirmpassword}
                onChange={handleNumberChange}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                autoFocused
                inputRef={passwordRef} // Set the ref for the password input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handlePasswordEnterPress();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggle}
                      edge="end"
                      className="pe-3"
                      aria-label="toggle password visibility"
                    >
                      {isVisible ? (
                        <img
                          className="img-fluid img_plexar_width"
                          src="/images/eye_open.svg"
                          alt="no-data"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <img
                          className="img-fluid img_plexar_width"
                          src="/images/eye-off-line 1.png"
                          alt="no-data"
                          style={{ width: "20px", height: "20px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  className="ms-2 me-2"
                  role="button"
                />
                <span style={{ fontSize: "12px" }}>Remember Me</span>
              </div>
              <div
                className="d-flex justify-content-end text-primary"
                role="button"
                style={{
                  fontSize: "12px",
                }}
                onClick={handleForgetPassword}
              >
                <u>Forgot Password?</u>
              </div>
            </div>
            <button
              className="btn btn-primary button_width btn-lg"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="bottom_attach px-3 pt-2 center_copyright">
        <div className="show_privacy_policy">
          <p
            className="sub_heading2"
            role="button"
            onClick={() => history.push("/privacy")}
          >
            Privacy Policy
          </p>{" "}
          &nbsp; &nbsp; &nbsp;
          <p
            className="sub_heading2"
            role="button"
            onClick={() => history.push("/terms")}
          >
            Terms & Conditions
          </p>
        </div>
        <p className="sub_heading2">
          Copyright © 2023 Plexaar. All rights reserved.
        </p>
      </div>
    </div>
  );
};
export default Password;
