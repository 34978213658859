import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  addusermobile,
  getuserdataafterlogin,
  getuserdataafterloginsuccess,
  permissionModule,
} from "../../../Store/Auth/actions";
import { isValidPhoneNumber } from "libphonenumber-js";
import "../Login/Login.scss";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/material.css";
import { addSelectedBusiness } from "../../../Store/Business/actions";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  ClearAllSession,
  encryptURL,
  getCookiesDataInSubDomain,
  getPrevUrls,
} from "../../../utils/helper";
import Footer from "../Footer";

export default function Addnumber(props) {
  const [value, setValue] = useState();
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const userid = useSelector((state) => state.Auth?.registersuccessId);
  const dispatch = useDispatch();
  const history = useHistory();
  const [authToken, setAuthToken] = useState("");
  const [isValid, setIsValid] = useState(false);
  let businessList = useSelector((state) => state.Business?.data);
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const auth = getCookiesDataInSubDomain("auth");

  var userID = useSelector((state) => state.Auth?.userloginsuccessyasir?.users);
  console.log(authToken, "authauthauth");
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const t = await executeRecaptcha("submit");
    setToken(t);
    // Do whatever you want with the token
  }, [executeRecaptcha]);
  const addnumber = async () => {
    await handleReCaptchaVerify();
    console.log(token, "tokentokentoken");
    // Check if the token is available
if(authToken){
    dispatch(
      addusermobile({
        id: auth && auth?.user && auth?.user?.id,
        mobileNumber: value,
        type: 1,
        modifiedBy: auth && auth?.user && auth?.user?.id,
        raptchaToken: authToken ? authToken : token,

        setIsLoading,
      })
    );
  };
}
  const handleToken = (token) => {
    setIsLoading(true);
    setAuthToken(token);
    setIsLoading(false);
    return token;
  };

  const handlePhoneNumberChange = (phoneNumber, country) => {
    setValue(phoneNumber);
    const isValidNumber = isValidPhoneNumber(phoneNumber, country);
    setIsValid(isValidNumber);
  };
  const handleSelectedBusiness = useCallback(
    (business) => {
      if (businessList.length === 0) {
        dispatch(addSelectedBusiness(null));
      } else {
        dispatch(addSelectedBusiness(business));
      }
    },
    [businessList, dispatch]
  );

  useEffect(() => {
    if (businessList.length > 0 && selectedBusiness === null) {
      handleSelectedBusiness(businessList[0]);
      dispatch(
        permissionModule({
          userID,
          businessID: businessList[0].id, // Assuming there's a property called 'id' in your business object
        })
      );
    }
  }, [
    businessList,
    selectedBusiness,
    handleSelectedBusiness,
    dispatch,
    userID,
  ]);
  const targetURL = getPrevUrls();
  useEffect(() => {
    getuserdataafterloginsuccess({
      id: JSON.stringify(auth?.userId),
    });
  }, []);
  const handleSkip = () => {
    const url = localStorage.getItem("url");

    const targetURL = url || "https://calendex-staging.plexaar.com/";
    const encryptedToken = encryptURL(auth?.token);
    const encryptedUserId = encryptURL(JSON.stringify(auth?.userId));
    const encryptedUser = encryptURL(JSON.stringify(userID));
    const encryptedRefreshToken = encryptURL(auth?.jwtRefreshToken);

    const redirectObject = {
      token: encryptedToken,
      userId: encryptedUserId,
      user: encryptedUser,
      jwtRefreshToken: encryptedRefreshToken,
      deviceId:localStorage.getItem("deviceId")

    };

    window.location.href = `${targetURL}?encryptedData=${encodeURIComponent(
      JSON.stringify(redirectObject)
    )}`;
    ClearAllSession();
  };
  const authe = getCookiesDataInSubDomain("auth");
  return (
    <div className="login_height">
      <div>
        {businessList.length > 0 ? (
          <div
            // href="/dashboard"
            style={{
              position: "absolute",
              top: "30px",
              right: "50px",
            }}
            className="skip-button"
            onClick={handleSkip}
          >
            Skip
          </div>
        ) : (
          <div
            // href="/dashboard"
            style={{
              position: "absolute",
              top: "30px",
              right: "50px",
            }}
            className="skip-button"
            onClick={handleSkip}
          >
            Skip
          </div>
        )}
      </div>
      {!authToken && <GoogleReCaptcha onVerify={handleToken} />}

      <div className="container mb-5 pb-5">
        <div className="container paddings">
          <div className="pt-5 mt-5text-center alignment_vertically">
            <img
              className="img-fluid pb-4"
              src="/images/plex-logo.svg"
              style={{ width: "151px" }}
            />
            {/* <div className="pb-3">
              <img
                className="img-fluid img_plexar_width"
                src="../images/plexaar final 2 round-15 1.png"
                alt="no-data"
                style={{ width: "100px", height: "100px" }}
              />
              <div>
                <img
                  className="img-fluid img_plexar_width"
                  src="../images/plexaar text-22 1.png"
                  alt="no-data"
                  style={{ width: "111px", height: "46" }}
                />
              </div>
            </div> */}
            <h3>
              <b>
                Profile <span className="plexar_font">Information</span>
              </b>
            </h3>
            <p className="sub_heading mb-0 text-wrap">
              Dear user please add your personal information to complete
              Registration
            </p>
            <div className="pt-3">
              <div className="ps-2 pt-4">
                <PhoneInput
                  //  inputStyle={{color:'green'}}
                  //  containerStyle={{backgroundColor:"#000"}}
                  buttonStyle={{ borderRight: "1px solid #DCDCDC" }}
                  //  dropdownStyle={{height:'50px'}}
                  inputStyle={{
                    width: "97%",
                    height: "46px",
                    color: " #616161",
                    border: "1px solid #DCDCDC",
                    borderRadius: "8px",
                    paddingLeft: "70px",
                    // paddingRight: "40px",
                    transition:
                      "background-color 0.3s, color 0.3s, border-color 0.3s",
                  }}
                  country={"pk"}
                  inputProps={{
                    onFocus: (e) => {
                      e.target.style.backgroundColor = "white";
                    },
                    onBlur: (e) => {
                      e.target.style.backgroundColor = "";
                    },
                  }}
                  value={value}
                  onChange={(phoneNumber, country) =>
                    handlePhoneNumberChange(phoneNumber, country)
                  }
                  // className="phone-input"
                />
              </div>
              <div className="pb-4 px-0 pt-1">
                <button
                  className={`btn btn-primary ${
                    value === undefined || value.length <= 7
                      ? "button_width_disabled"
                      : "button_width"
                  } btn-lg`}
                  onClick={addnumber}
                  disabled={value === undefined || value.length <= 7}
                  // disabled={authToken ? false : true&& value===""}
                  // onClick={() =>
                  //   history.push(`/verify-number/${value}`)
                  // }
                >
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>Confirm</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer hsitory={history} />
    </div>
  );
}
