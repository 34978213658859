import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword, setuserpassword } from "../../../Store/Auth/actions";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputTextField from "../../BusinessProfile/TextField";
import "../Login/Login.scss";
import { useHistory } from "react-router-dom";
import Footer from "../Footer";

export default function ResetPassword(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  const userotp = useSelector((state) => state.Auth?.otpuser);
  const userid = useSelector((state) => state.Auth?.forgetPassword?.result?.userId);
  const [password, setPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const confirmpasswordRef = useRef(null);
  const passwordRef = useRef(null);
  const history = useHistory();
  const accountNumber = useSelector((state) => state.Auth?.forgetPassword?.result?.accountNumber);

  const dispatch = useDispatch();
  useEffect(() => {
    passwordRef.current.focus();
  }, []);
  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle1 = () => {
    setVisible1(!isVisible1);
  };
  const handleNumberChange = (event) => {
    const valuew = event.target.value;
    setPassword(valuew);
    // setValid(validateEmail(valuew));
  };
   const handleOldrChange = (event) => {
    const valuew = event.target.value;
    setPassword(valuew);
    // setValid(validateEmail(valuew));
  };

  const handleNumberChangeNew = (event) => {
    const valuew = event.target.value;
    setConfirmpassword(valuew);
    // setValid(validateEmail(valuew));
  };
  const handleSubmit = () => {
    dispatch(
      changeUserPassword({
        id: userid,
        password: password,
        otp: userotp,
        accountNumber:accountNumber,
        setIsLoading,
      })
    );
  };

  /////check------------------
  // const hasUppercase = /[A-Z]/;
  // const hasLowercase = /[a-z]/;
  const hasNumber = /[0-9]/;
  const hasMinimumLength = /^.{8,}$/;
  const hasSymbol = /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
  // const checkUppercase = (password) => hasUppercase.test(password);
  // const checkLowercase = (password) => hasLowercase.test(password);
  const checkNumber = (password) => hasNumber.test(password);
  const eightcharacters = (password) => hasMinimumLength.test(password);
  const atleastonesymbol = (password) => hasSymbol.test(password);

  const renderCheck = (check) => {
    return check ? (
      <img
        className="img-fluid img_plexar_width"
        src="../images/tick.svg"
        alt="no-data"
        style={{ width: "20px", height: "20px" }}
      />
    ) : (
      <img
        className="img-fluid img_plexar_width"
        src="../images/cross.svg"
        alt="no-data"
        style={{ width: "20px", height: "20px" }}
      />
    );
  };

  const handleEnterPress = () => {
    confirmpasswordRef.current.focus(); // Focus on the password input field
  };

  const handlePasswordEnterPress = () => {
    handleSubmit(); // Submit the form
  };
  return (
    <div className="login_height create_password_padding">
      <div className="container mb-5 pb-5">
        <div className="container padding_in_create_password text-center alignment_vertically">
          <div className="pb-3">
            <img
              className="img-fluid img_plexar_width"
              src="../images/plexaar final 2 round-15 1.png"
              alt="no-data"
              style={{ width: "100px", height: "100px" }}
            />
            <div>
              <img
                className="img-fluid img_plexar_width"
                src="../images/plexaar text-22 1.png"
                alt="no-data"
                style={{ width: "111px", height: "46" }}
              />
            </div>
          </div>
          <h3 className="pt-2">
            <b>
              Create <span className="plexar_font">Password</span>
            </b>
          </h3>
          <p className="sub_heading pt-2 text-wrap">
            Create strong and secure password that protect your account
          </p>
          <div className="col-md-12  pt-3">

        
            <InputTextField
              id="password"
              label="Password"
              name="password"
              placeholder="Enter Password"
              variant="filled"
              type={isVisible ? "text" : "password"}
              value={password}
              inputRef={passwordRef} // Set the ref for the password input
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleEnterPress();
                }
              }}
              onChange={handleNumberChange}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={toggle}
                    edge="end"
                    className="pe-3"
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? (
                      <img
                        className="img-fluid img_plexar_width"
                        src="../images/eye_open.svg"
                        alt="no-data"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <img
                        className="img-fluid img_plexar_width"
                        src="../images/eye-off-line 1.png"
                        alt="no-data"
                        style={{ width: "20px", height: "20px" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <div className="input-group mt-2">
              <InputTextField
                id="confirmpassword"
                label="Confirm Password"
                name="confrimpassword"
                placeholder="Confirm Password"
                variant="filled"
                type={isVisible1 ? "text" : "password"}
                value={confirmpassword}
                inputRef={confirmpasswordRef} // Set the ref for the password input
                onChange={handleNumberChangeNew}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handlePasswordEnterPress();
                  }
                }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggle1}
                      edge="end"
                      className="pe-3"
                      aria-label="toggle password visibility"
                    >
                      {isVisible1 ? (
                        <img
                          className="img-fluid img_plexar_width"
                          src="../images/eye_open.svg"
                          alt="no-data"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <img
                          className="img-fluid img_plexar_width"
                          src="../images/eye-off-line 1.png"
                          alt="no-data"
                          style={{ width: "20px", height: "20px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {confirmpassword !== "" && confirmpassword !== password && (
                <div className="text-danger w-100 text-center">
                  Password Does not match
                </div>
              )}
            </div>
          </div>

          <div className="align-items-baseline d-flex flex-column justify-content-start">
            <p className="mb-0 font_size_password pt-2">
              {renderCheck(eightcharacters(password))} &nbsp; At least eight
              characters
            </p>
            <p className="mb-0 font_size_password pt-2">
              {renderCheck(checkNumber(password))} &nbsp;At least one number
            </p>
            <p className="mb-0 font_size_password pt-2">
              {renderCheck(atleastonesymbol(password))} &nbsp; !"£$%^&* at least
              one symbol
            </p>
          </div>

          <div className="d-flex pt-3 custom-control custom-checkbox text-start">
            <input
              type="checkbox"
              className="custom-control-input pt-1"
              id=""
              onChange={(e) => setIsChecked(e.target.checked)}
              checked={isChecked}
            />
            <label className="custom-control-label ms-3" htmlFor="customCheck1">
              <span className="font_label">
                I agree to the Expert{" "}
                <u
                  className="text-primary"
                  role="button"
                  onClick={() => history.push("/terms")}
                >
                  Terms of Services
                </u>{" "}
                and{" "}
                <u
                  className="text-primary"
                  role="button"
                  onClick={() => history.push("/privacy")}
                >
                  Privacy Policy
                </u>
              </span>
            </label>
          </div>

          <div className="col-md-12 pb-5 pt-1 px-0">
            {checkNumber(password) &&
            atleastonesymbol(password) &&
            eightcharacters(password) &&
            isChecked &&
            password === confirmpassword &&
            password !== "" ? (
              <button
                className="btn btn-primary button_width btn-lg"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <> Save & Continue</>
                )}
              </button>
            ) : (
              <button className="btn btn-primary button_width_disabled btn-lg">
                {" "}
                Save & Continue{" "}
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer
     history={history}/>
    </div>
  );
}
