import axios from "../../Routes/AxiosConfig";
import {
  fork,
  put,
  all,
  select,
  takeLatest,
  call,
  take,
} from "redux-saga/effects";
import axiosConfig from "../../Routes/AxiosConfigg";
import CryptoJS from "crypto-js";
import packageJson from "../../../package.json";
// Login Redux States
import {
  LOGIN,
  REGISTER_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  REGISTERAPI,
  SEND_OTP_EMAIL,
  SET_USER_PASSWORD,
  ADD_USER_PROFILE,
  ADD_USER_MOBILE,
  MOBILE_OTP_VERIFY,
  GET_USER_DATA_AFTER_LOGIN,
  ADD_SECONDARY_EMAIL,
  VERIFY_SECONDARY_EMAIL,
  SEND_OTP_SECONDRY_EMAIL,
  ADD_SECONDARY_PHONE_NUMBER,
  VERIFY_SECONDARY_MOBILE,
  RESEND_OTP_MOBILE,
  GET_ALL_GENDERS,
  SEND_GENDER,
  ADD_DOB,
  SIGNIN_USER,
  RESEND_MOBILE_PRIMARY_OTP,
  RESEND_OTP_NEW_PRIMARY_EMAIL,
  RESEND_OTP_SECONDARY_EMAIL,
  NEW_OTP_RESEND_PRIMARY_MOBILE,
  UPDATE_USER_NAME,
  UPLOAD_PROFILE_IMG,
  PERMISSION_API,
  FORGET_PASSWORD,
  CHANGE_USER_PASSWORD,
  CHANGE_USERS_PASSWORD,
} from "./actionTypes";
import {
  addsecondaryemailsuccess,
  addsecondaryphonenumbersuccess,
  addusermobilesuccess,
  forgetapisuccess,
  getallgenderssuccess,
  getuserdataafterlogin,
  getuserdataafterloginsuccess,
  loginSuccess,
  permissionModuleSucess,
  registerUserSuccessful,
  registerapisuccess,
  sendgendersuccess,
  sendotopemailsuccess,
  setuserpasswordsuccess,
  uploadprofileimagesuccess,
  userpayloadsuccess,
  verfysecondarymobilesuccess,
  verifymobileotpsuccess,
  verifysecondaryemailsuccess,
} from "./actions";
import { push } from "connected-react-router";
import { sagaErrorHandler } from "../../Shared/HelperMethods/SagaErrorHandler";
import { toast } from "react-toastify";
import { makeSelectAuthToken } from "../selector";
import { DEVICE_ID } from "../../Constants/ImgConstants";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedBusiness, fetchBusinessRequest } from "../Business/actions";
// import { resetdate } from "../../Calendar/scheduler/store/dateSlice";
import {
  ClearAllSession,
  encryptURL,
  fetchIPDetails,
  getCookiesDataInSubDomain,
  getDeviceFingerprints,
  getDeviceInfo,
  getDeviceType,
  getIPAddress,
  getLoginDetails,
  getOSVersion,
  getPrevUrls,
  setCookiesData,
  setCookiesDataWithTime,
  setCookiesDataWithTimeInSubDomain,
  setStorageData,
} from "../../utils/helper";
import { customSnackBar } from "../../utils";
import { FETCH_BUSINESS_SUCCESS } from "../Business/actionType";
import { WindowSharp } from "@mui/icons-material";
import instance from "../../utils/utils";

//If user is login then dispatch redux action's are directly from here.
// function* loginUser({ payload }) {
//   try {
//     payload.setIsLoading(true);
//     let data = {
//       email: payload.email,
//       password: payload.password,
//     };
//     const response = yield axios.post("admin/login", data);
//     if (response.data.data.role === "Admin") {
//       payload.history.push("/Dashboard-Admin");
//     } else {
//       payload.history.push("/dashboard");
//     }
//     yield put(loginSuccess(response.data.data));
//     payload.setIsLoading(false);
//   } catch (error) {
//     yield sagaErrorHandler(error.response.data);
//     payload.setIsLoading(false);
//   }
// }
// const loading = useSelector((state) => state.Business?.loading);
function* registerUser({ payload }) {
  try {
    let data = {
      name: payload.name,
      email: payload.email,
      password: payload.password,
    };
    const response = yield instance.post("auth/register", data);

    yield put(registerUserSuccessful(response.data));
    yield put(push("/on-boarding"));
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
  }
}

function* forgotPasswordRequest({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      email: payload.email,
    };
    yield instance.post("admin/forgetPassword", data);
    payload.history.push("/login");
    // toast.success("Please check you email");

    payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* resetPasswordRequest({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      token: payload.token,
      newPassword: payload.password,
    };
    yield instance.put("admin/resetPassword", data);
    // toast.success("Password reset successfully");
    payload.history.push("/login");
    payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* changePasswordRequest({ payload }) {
  const token = yield select(makeSelectAuthToken());
  try {
    let data = {
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword,
    };
    const response = yield instance.put("admin/changePassword", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    payload.history.push("/Dashboard-Admin");
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
  }
}

///////////////////////////////////////functions for plexar

function* registeruser({ payload }) {
  payload.setIsLoading(true);
  try {
    // let data = {
    //   name: payload.name,
    //   email: payload.email,
    //   password: payload.password,
    //      deviceId: payload.deviceId,
    // countryId: payload.countryId,
    // };
      const deviceData = yield call(getDeviceFingerprints);
localStorage.setItem("deviceId",deviceData?.uniqueId)
const plarformId= parseInt(localStorage.getItem("platFormId"))
    let data = {
      email: payload.email,
      deviceId: deviceData?.uniqueId,
      countryId: payload.countryId,
      //  deviceId: payload.deviceId,
      plarformId:plarformId?plarformId:1,
      // isMobile: false,
      // isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      // isAndroidRequest: false,
    };
    const flag = false;

    const response = yield instance.post("signup_svc/pb/users/tempregister", data);
    // console.log("response12121", response)
    // yield put(push(`/Email-verification/${payload.email}`));
    if (response.data.code === 0) {
      yield put(push(`/Email-verification/${payload.email}`, flag));
    } else if (response.data.code === 6) {
      yield put(push(`/Email-verification/${payload.email}`, flag));
      // } else if (response.data.code === 4) {
      //   yield put(push(`/Profile-information`));
    } else if (response.data.code === 3 || response.data.code === 4) {
      yield put(push(`/password/${payload.email}/${window.location.search}`));
    } else if (response.data.code === -1||response.data.code === 1) {
      customSnackBar(response.data.message);
      window.location.reload()
    } else if (response.data.code === 8) {
      yield put(push(`/Updated-Email-verification/${payload.email}`, flag));
    } else {
      customSnackBar(response.data.message);
    }
    yield put(registerapisuccess(response.data));
  } catch (error) {
    customSnackBar(error?.request?.response)
    error(error);

  } finally {
    payload.setIsLoading(false);
  }
}

function* forgetPassword({ payload }) {
  payload.setIsLoading(true);
const plarformId= parseInt(localStorage.getItem("platFormId"))

  try {
    let data = {
      email: payload.email,
      plarformId:plarformId?plarformId:1,

      // isMobile: false,
      // isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      // isAndroidRequest: false,
      // deviceId: payload.deviceId,
      // countryId: payload.countryId,
    };
    const response = yield instance.post(
      "/signup_svc/pb/users/forgetPassword",
      data
    );
    const flag = true;
    // yield put(push(`/Email-verification/${payload.email}`));
    if (response.data.code === 0) {
      yield put(push(`/Email-verification/${payload.email}`, flag));
    } else if (response.data.code === 6) {
      yield put(push(`/Email-verification/${payload.email}`, flag));
      // } else if (response.data.code === 4) {
      //   yield put(push(`/Profile-information`));
    } else if (response.data.code === 3 || response.data.code === 4) {
      yield put(push(`/password/${payload.email}`));
    } else {
      customSnackBar(response.data.message);
    }
    yield put(forgetapisuccess(response.data));
  } catch (error) {
    customSnackBar("Something went Wrong");

    window.location.reload()
  } finally {
    payload.setIsLoading(false);
  }
}

function* sendotpemail({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      otpCode: payload.otpCode,
      email: payload.email,
      userId: payload.userId,
      deviceId: DEVICE_ID,
      isResetPassword: payload.flag ? true : false,
      isDeleteAccount: false,
    };
    yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );

    if (response.data.code === 0) {
      if (payload.location.includes("/Updated-Email-verification")) {
        console.log(payload, "SUCCESSFUL OTP PAYLOAD");
        yield put(push(`/password/${payload.email}`));
      } else if (payload.flag) {
        yield put(push(`/reset-user-password`));
      } else {
        yield put(push(`/create-password`, payload.email));
      }
    } else {
      customSnackBar(response.data.message);
    }
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setOtp("");
    payload.setIsLoading(false);
  }
}
function* setuserrpasswordfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      email: payload.email,
      password: payload.password,
      otp: payload.otp,
      deviceId: DEVICE_ID,
    };

    const response = yield instance.post(
      "signup_svc/pb/users/addUsertempPassword",
      data
    );
    yield put(setuserpasswordsuccess(response.data.result));
    // setCookiesData(
    //   "auth",
    //   JSON.stringify({
    //     token: response?.data?.result?.jwtToken,
    //     userId: response?.data?.result?.users?.id,
    //   })
    // );
    setCookiesDataWithTimeInSubDomain(
      "auth",
      JSON.stringify({
        jwtRefreshToken: response?.data?.result?.jwtRefreshToken,

        token: response?.data?.result?.jwtToken,
        userId: response?.data?.result?.users?.id,
        user: response?.data?.result?.users,
      })
    );
    localStorage.setItem("token", response?.data?.result?.jwtToken);
    localStorage.setItem("user", JSON.stringify(response?.data));
    yield put(push(`/Profile-information`));
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}
function* adduserprofilefunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      firstName: payload.firstName,
      lastname: payload.lastname,
      imageUrl: payload.imageUrl,
      modifiedBy: payload.modifiedBy,
    };
    // yield put(sendotopemailsuccess(payload));
    // const response = yield instance.post(
    //   "signup_svc/pv/users/addUserProfile",
    //   data
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pv/users/addUserProfile",
      data
    );
    yield put(push(`/add-number`));
    // yield put(push(`/Profile`));

    // toast.success(response.data.message);
    payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* addusermobilefunc({ payload }) {
  try {
const plarformId= parseInt(localStorage.getItem("platFormId"))
    
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      mobileNumber: payload.mobileNumber,
      type: payload.type,
      modifiedBy: payload.modifiedBy,
      plarformId:plarformId?plarformId:1,

      // isMobile: false,
      // isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      // isAndroidRequest: false,
    };

    // const response = yield instance.post(
    //   "signup_svc/pv/users/addUserMobiles",
    //   data
    // );
    const response = yield call(
      instance.post,
      "signup_svc/pv/users/addUserMobiles",
      data
    );
    yield put(addusermobilesuccess(payload));
    // alert("verified");
    if (response.data.code === 0) {
      yield put(push(`/verify-number/${payload.mobileNumber}`));
      // toast.success(response.data.message);
    } else if (response.data.code === 1) {
      customSnackBar(response.data.message);
    } else {
      customSnackBar(response.data.message);
    }
    payload.setIsLoading(false);
  } catch (error) {
    payload.setIsLoading(false);
  }
}
function* mobileotpvrtifyy({ payload }) {
  try {
    let data = {
      otpCode: payload.otpCode,
      phone: payload.phone,
      userId: payload.userId,
      isResetPassword: false,
      isDeleteAccount: false,
    };
    let mobileprimaryverify = true;
    payload.setIsLoading(true);
    const response = yield instance.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );
    const url = localStorage.getItem("url")
    console.log(url,"uudfiudsi")
    const targetURL = url ?url: "https://calendex.plexaar.com/";
    console.log(url,targetURL,"uudfiudsi")

    const auth = getCookiesDataInSubDomain("auth");
    const encryptedToken = encryptURL(auth?.token);
    const encryptedUserId = encryptURL(JSON.stringify(auth?.userId));
    const encryptedUser = encryptURL(JSON.stringify(auth?.user));
    const encryptedRefreshToken = encryptURL(auth?.jwtRefreshToken);

    const redirectObject = {
      token: encryptedToken,
      userId: encryptedUserId,
      user: encryptedUser,
      jwtRefreshToken: encryptedRefreshToken,
      deviceId:localStorage.getItem("deviceId")
    };
    console.log(redirectObject, auth, "redirectObject");
    if (response.data.code === 0) {
      window.location.href = `${targetURL}?encryptedData=${encodeURIComponent(
        JSON.stringify(redirectObject)
      )}`;

      // yield put(push(`/dashboard`));
      yield put(verifymobileotpsuccess(mobileprimaryverify));
    } else {
      customSnackBar(response.data.message);
    }
  } catch (error) {
    // customSnackBar(error);
  } finally {
    payload.setOtp("");
    payload.setIsLoading(false);
  }
}
function* getuserdetaillogin({ payload }) {
  // const token = yield select(makeSelectAuthToken());

  try {
    const useridlogin = payload.id;
    if (payload.setLoader) {
      payload.setLoader(true);
    }
    const response = yield instance.get(
      `signup_svc/pv/users/getUserById?id=${useridlogin}`
    );
    yield put(getuserdataafterloginsuccess(response.data.result.user));
  } catch (error) {
    yield sagaErrorHandler(error);
  } finally {
    if (payload.setLoader) {
      payload.setLoader(false);
    }
  }
}
function* addsecondaryemailfunc({ payload }) {
  try {
const plarformId= parseInt(localStorage.getItem("platFormId"))

    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      email: payload.email,
      modifiedBy: payload.modifiedBy,
      plarformId:plarformId?plarformId:1,

      // isMobile: false,
      // isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      // isAndroidRequest: false,

      // isMobile: false,
      // isCrossPlatForm: false,
      // raptchaToken: "string",
      // isAndroidRequest: false,
      // mobileCaptchaData: {
      //   projectId: "string",
      //   recaptchaAction: "string",
      //   recaptchaSiteKey: "string",
      // },
    };

    // yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "signup_svc/pv/users/addSecondaryEmail",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/add-secondary-email-otp-verify`));
      yield put(addsecondaryemailsuccess(payload.email));
    } else {
      customSnackBar(response.data.message);
      yield put(push(`/add-secondary-email`));
    }
    payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* verifysecondaryemailfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      otpCode: payload.otpCode,
      email: payload.email,
      userId: payload.userId,
      deviceId: DEVICE_ID,
    };
    let emailsecondryverify = true;
    yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      yield put(verifysecondaryemailsuccess(emailsecondryverify));
      yield put(push(`/Profile`));
    } else {
      yield put(push(`/add-secondary-email-otp-verify`));
      customSnackBar(response.data.message);
    }
    payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* sendotpsecondaryfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      // id: payload.id,
      email: payload.email,

      type: payload.type,
    };
    payload.setIsLoading(true);
    yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "signup_svc/pb/users/resendEmailOtpNew",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/add-secondary-email-otp-verify`));
      // toast.success(response.data.message);
      payload.setIsLoading(false);
    } else {
      yield put(push(`/allemailsuser`));
      customSnackBar(response.data.message);
      payload.setIsLoading(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* addsecondaryphonefunc({ payload }) {
  try {
    payload.setIsLoading(true);
const plarformId= parseInt(localStorage.getItem("platFormId"))

    let data = {
      id: payload.id,
      mobileNumber: payload.mobileNumber,
      type: payload.type,
      modifiedBy: payload.modifiedBy,
      plarformId:plarformId?plarformId:1,

      // isMobile: false,
      // isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      // isAndroidRequest: false,
    };
    // yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "signup_svc/pv/users/addUserMobiles",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/verify-secondary-mobile-number`));
      yield put(addsecondaryphonenumbersuccess(payload.mobileNumber));
      payload.setIsLoading(false);
    } else {
      customSnackBar(response.data.message);
      yield put(push(`/add-secondary-mobile-number`));
      payload.setIsLoading(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}
function* verifysecondarymobilefunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      otpCode: payload.otpCode,
      phone: payload.phone,
      userId: payload.userId,
      deviceId: DEVICE_ID,
    };
    let mobilesecondryverify = true;
    // yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "otpgeneration_svc/pb/verify/otp/plexaar/",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/Profile`));
      yield put(verfysecondarymobilesuccess(mobilesecondryverify));
    } else {
      yield put(push(`/verify-secondary-mobile-number`));
      customSnackBar(response.data.message);
    }
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* resetUserPasswordRequest({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      id: payload.id,
      password: payload.password,
      otp: payload.otp,
    };

    const response = yield instance.post(
      "/signup_svc/pb/users/resetUserPassword",
      data
    );
    yield put(setuserpasswordsuccess(response.data.result));
    setCookiesDataWithTimeInSubDomain(
      "auth",
      JSON.stringify({
        jwtRefreshToken: response?.data?.result?.jwtRefreshToken,

        token: response?.data?.result?.jwtToken,
        userId: response?.data?.result?.users?.id,
        user: response?.data?.result?.users,
      })
    );
    localStorage.setItem("token", response?.data?.result?.jwtToken);
    localStorage.setItem("user", JSON.stringify(response?.data));
    yield put(push(`/`));
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* changeUserPasswordRequests({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      userId: payload.id,
      currentpassword: payload.currentpassword,
      newpassword: payload.newpassword,
    };

    const response = yield instance.put(
      "/signup_svc/pv/users/changePassword",
      data
    );
    // yield put(setuserpasswordsuccess(response.data.result));
    if (response?.data?.code === 0) {
      yield put(push(`/Profile`));
    } else {
      customSnackBar(response?.data?.message);
    }
  } catch (error) {
    customSnackBar(error);
  } finally {
    payload.setIsLoading(false);
  }
}

function* sendotpsecondarymobilefunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      type: payload.type,
    };
    // yield put(sendotopemailsuccess(payload));
    payload.setIsLoading1(true);
    const response = yield instance.post(
      "signup_svc/pb/users/resendMobileOtp",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/verify-secondary-mobile-number`));
      // toast.success(response.data.message);
      payload.setIsLoading1(false);
    } else {
      // yield put(push(`/allemailsuser`));
      customSnackBar(response.data.message);
      payload.setIsLoading1(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading1(false);
  }
}

function* getallgendersfunc({ payload }) {
  // const token = yield select(makeSelectAuthToken());

  try {
    // payload.setIsLoading(true);
    // Reload the window
    // const useridlogin = payload.id;
    const response = yield instance.get(`signup_svc/pv/genders/getAllGender`);
    // {
    //   headers: {
    //     Authorization: `Token  ${token}`,
    //   },
    // });
    yield put(getallgenderssuccess(response.data.result.genders));
    // payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* sendgenderfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      genderId: payload.genderId,
      modifiedBy: payload.modifiedBy,
    };

    // yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "signup_svc/pv/users/addUsergender",
      data
    );
    yield put(push(`/Profile`));
    // yield put(sendotopemailsuccess(sendgendersuccess));
    // if(response.data.code === 0){
    //   yield put(push(`/verify-secondary-mobile-number`));
    //   alert("code delivered")
    // }else{
    //   // yield put(push(`/allemailsuser`));
    //   alert("api failed")
    // }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* addsobfunctionfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      dob: payload.dob,
      modifiedBy: payload.modifiedBy,
    };

    // yield put(sendotopemailsuccess(payload));
    const response = yield instance.post("signup_svc/pv/users/addUserDob", data);
    yield put(push(`/Profile`));
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    // payload.setIsLoading(false);
  }
}
function* getBusienss(id) {
  try {
    const response = yield instance.get(
      `business_svc/pb/business?userId=${id}`
    );
    return response.data; // Return the fetched business data
  } catch (error) {
    // Handle error
    console.error(error);
    return null;
  }
}

function* signinuserfunc({ payload }) {
  try {
    payload.setIsLoading(true);
    let data = {
      email: payload.email,
      password: payload.password,
      deviceId: DEVICE_ID,
      uniqueId: "string",
      devicePlatform: "string",
      pushToken: "string",
    };
    const url = localStorage.getItem("url")
    console.log(url,"uudfiudsi")
    const targetURL = url?url: "https://calendex.plexaar.com/";
    // const targetURL = getPrevUrls() || "https://calendex.plexaar.com/";
    let redirectUrl;
    console.log(targetURL, "targetURLtargetURL");
    const response = yield instance.post("signup_svc/pb/users/signIn", data);
    if (response.data.code === 1) {
      // payload.setpassword("");
      customSnackBar(response.data.message);
    } else if (response.data.code === 0 || response.data.code === 4) {
      const result = response?.data?.result;
      console.log("TRIGGERING");

      console.log(result.users, "DEV_DATA 2");
      const deviceData = yield call(getDeviceFingerprints);
      const ipAddress = yield call(getIPAddress);
      const OSVersion = yield call(getOSVersion);

      console.log(OSVersion, "DEV_DATA");
      let deviceDataInfo = {
        userId: result.users.id,
        createdBy: result.users.id,
        platform: "Web",
        deviceType: getDeviceType(),
        ipAddress: DEVICE_ID,
        countryId: result.users.countryId,
        deviceName: navigator.userAgentData
          ? navigator.userAgentData.platform
          : "Safari",
        appVersion: packageJson.version,
        uniqueId: deviceData.uniqueId,
        deviceOSVersion: OSVersion,
        fcm: "",
      };
      const r = instance.post(
        "/signup_svc/pb/users/AddUserDevice",
        deviceDataInfo
      );
      console.log(deviceDataInfo, "DEV_DATA_MAIN");
      yield put(userpayloadsuccess(result));
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify({
          token: result?.jwtToken,
          userId: result?.users?.id,
          user: result?.users,

          businessData: response?.data?.businessData,
        }),
        "9GxbhX0FNMP"
      ).toString();
      const encryptedToken = encryptURL(result?.jwtToken);
      const encryptedRefreshToken = encryptURL(result?.jwtRefreshToken);
      const encryptedUserId = encryptURL(JSON.stringify(result?.users?.id));
      const encryptedUser = encryptURL(JSON.stringify(result?.users));
      const redirectObject = {
        token: encryptedToken,
        userId: encryptedUserId,
        user: encryptedUser,
        jwtRefreshToken: encryptedRefreshToken,
        deviceId: deviceData.uniqueId,
      };

      setCookiesDataWithTimeInSubDomain(
        "auth",
        JSON.stringify({
          jwtRefreshToken: result?.jwtRefreshToken,

          token: result?.jwtToken,
          userId: result?.users?.id,
          user: result?.users,
          businessData: response?.data?.businessData,
        }),
        payload.rememberMe
      );
      // localStorage.setItem("token", result?.jwtToken);
      // localStorage.setItem("user", JSON.stringify(response?.data));
      yield put(fetchBusinessRequest(result?.users?.id));
      const businessData = yield take(FETCH_BUSINESS_SUCCESS);

      if (businessData?.payload?.length === 0 && targetURL && redirectObject) {
        // redirectUrl = "/dashboard";
        console.log("TEST1");
        const movenext = `${targetURL ?? "https://plexaar.com/"
          }?encryptedData=${encodeURIComponent(JSON.stringify(redirectObject))}`;
        // yield put(push(movenext));
        window.location.replace(movenext)
        ClearAllSession();

        // // redirectUrl =`?encryptedData=${encodeURIComponent(
        // //   JSON.stringify(redirectObject)
        // // )}`;
        // console.log(redirectUrl, "redirectUrlredirectUrlredirectUrl");
        // yield put(push(`${targetURL?? "https://calendex-staging.plexaar.com/"}/dashboard`));
      } else {
        if (
          result?.users?.defaultAccountId !== 0 &&
          result?.users?.isBusinessAccount === true && targetURL && redirectObject
        ) {
          // redirectUrl = "/dashboard";
          // redirectUrl = "/dashboard";
          console.log(businessData, result?.users?.defaultAccountId, "TEST2");

          // yield put(resetdate());
          window.location.href = `${targetURL ?? "https://plexaar.com/"
            }?encryptedData=${encodeURIComponent(
              JSON.stringify(redirectObject)
            )}`;

          ClearAllSession();
          const businessId = result?.users?.defaultAccountId;
          const selectedBusiness = businessData.payload.find(
            (business) => business.id === businessId
          );
          console.log(businessData, selectedBusiness, "TEST7");

          if (selectedBusiness && targetURL && redirectObject) {
            yield put(addSelectedBusiness(selectedBusiness));
            // redirectUrl = "/calendar";
            console.log(selectedBusiness, "TEST2");

            window.location.href = `${
              targetURL ?? "https://calendex.plexaar.com/"
            }?encryptedData=${encodeURIComponent(
              JSON.stringify(redirectObject)
            )}`;

            ClearAllSession();

            // redirectUrl = `?encryptedData=${encodeURIComponent(
            //   JSON.stringify(redirectObject)
            // )}`;
            // console.log(redirectUrl, "redirectUrlredirectUrlredirectUrl1");

            // redirectUrl = `?endcodedData=${encryptedData}`;
            // yield put(push(`${targetURL?? "https://calendex-staging.plexaar.com/"}/calendar`));
          } else {
            yield put(permissionModule(selectedBusiness));
            console.log("TEST3");

            // redirectUrl = `?encryptedData=${encodeURIComponent(
            //   JSON.stringify(redirectObject)
            // )}`;
            // redirectUrl = "/dashboard";

            window.location.href = `${targetURL ?? "https://plexaar.com/"
              }?encryptedData=${encodeURIComponent(
                JSON.stringify(redirectObject)
              )}`;

            ClearAllSession();
            // console.log(redirectUrl, "redirectUrlredirectUrlredirectUrl2");

            // redirectUrl = `?endcodedData=${encryptedData}`;
            // yield put(push(`${targetURL?? "https://calendex-staging.plexaar.com/"}/dashboard`));
          }
        } else {
          console.log("TEST4");

          window.location.href = `${targetURL ?? "https://plexaar.com/"
            }?encryptedData=${encodeURIComponent(
              JSON.stringify(redirectObject)
            )}`;

          ClearAllSession();
          // redirectUrl = `?encryptedData=${encodeURIComponent(
          //   JSON.stringify(redirectObject)
          // )}`;

          // redirectUrl = `?endcodedData=${encryptedData}`;
          // yield put(push(`${targetURL?? "https://calendex-staging.plexaar.com/"}/dashboard`));
        }
      }
    } else {
      // redirectUrl = "/dashboard";
      console.log("TEST5");

      window.location.href = `${
        targetURL ?? "https://calendex.plexaar.com/"
      }?encryptedData=${encodeURIComponent(JSON.stringify())}`;
      ClearAllSession();

      // yield put(push(`${targetURL?? "https://calendex-staging.plexaar.com/"}/dashboard`));
      customSnackBar(response.data.message);
    }

    if (redirectUrl !== undefined) {
      // window.location.href = `${targetURL}${redirectUrl}`;
      getCookiesDataInSubDomain("auth");
    }
    // const finalRedirectUrl = `${targetURL ?? "https://calendex-staging.plexaar.com/"}${redirectUrl}`;
    // yield put(push(finalRedirectUrl));
  } catch (error) {
    console.error(error);
    // customSnackBar(error?.data?.message);
    // payload.setpassword("");
  } finally {
    payload.setIsLoading(false);
    // saveDeviceDetails({ payload });
  }
}

// function* resendprimarymobilefunc({ payload }) {
//   console.log(payload,"payloadpayloadpayload")
//   try {
//     // payload.setIsActive(true);
//     let data = {
//       id: payload.id,
//       type: payload.type,
//     };
//     // payload.setIsLoading2(true);
//     payload.setIsLoadingresend(true);
//     const response = yield instance.post(
//       "signup_svc/pb/users/resendMobileOtp",
//       data
//     );
//     if (response.data.code === 0) {
//       yield put(push(`/verify-number/${payload.usernumber}`));
//       // toast.success(response.data.message);
//       payload.setIsActive(true);
//       payload.setIsLoadingresend(false);
//       // payload.setIsLoading2(true);
//     } else {
//       customSnackBar(response.data.message);
//       payload.setIsLoadingresend(false);
//       // payload.setIsLoading2(true);
//     }
//   } catch (error) {
//     payload.setIsLoading2(true);
//   }
// }
function* resendprimarymobilefunc({ payload }) {
  try {
const plarformId= parseInt(localStorage.getItem("platFormId"))

    let data = {
      id: payload.id,
      type: payload.type,
      plarformId:plarformId?plarformId:1,
      
      // isMobile: false,
      // isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      // isAndroidRequest: false,
    };
    payload.setIsLoadingresend(true);
    const response = yield instance.post(
      "signup_svc/pb/users/resendMobileOtp",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      payload.setIsActive(true);
      payload.setIsLoadingresend(false);
    } else {
      // toast.success(response.data.message);
      payload.setIsLoadingresend(false);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoadingresend(false);
  }
}
function* resendprimaryemailnewotpfunc({ payload }) {
  try {
const plarformId= parseInt(localStorage.getItem("platFormId"))

    let data = {
      email: payload.email,
      // id: payload.id,
      type: payload.type,
      plarformId:plarformId?plarformId:1,

      // isMobile: false,
      // isCrossPlatForm: false,
      raptchaToken: payload.raptchaToken,
      // isAndroidRequest: false,
    };
    payload.setIsLoadingresend(true);
    const response = yield instance.post(
      "signup_svc/pb/users/resendEmailOtpNew",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      payload.setIsActive(true);
      payload.setIsLoadingresend(false);
    } else {
      // toast.success(response.data.message);
      payload.setIsLoadingresend(false);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoadingresend(false);
  }
}

function* resendotpsecondaryfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      // id: payload.id,
      email: payload.email,

      type: payload.type,
    };
    payload.setIsLoadingresend(true);
    yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "signup_svc/pb/users/resendEmailOtpNew",
      data
    );
    if (response.data.code === 0) {
      // yield put(push(`/add-secondary-email-otp-verify`));
      const isActive = true;
      payload.setIsActive(isActive);
      payload.setIsLoadingresend(false);
      // toast.success(response.data.message);
    } else {
      yield put(push(`/allemailsuser`));
      payload.setIsLoadingresend(false);
      customSnackBar(response.data.message);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

function* resendprimarymobilenewfunc({ payload }) {
  try {
    // payload.setIsLoading(true);
    let data = {
      id: payload.id,
      type: payload.type,
    };
    payload.setIsLoadingresend(true);
    // yield put(sendotopemailsuccess(payload));
    const response = yield instance.post(
      "signup_svc/pb/users/resendMobileOtp",
      data
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      payload.setIsActive(true);
      payload.setIsLoadingresend(false);
    } else {
      // yield put(push(`/allemailsuser`));
      customSnackBar(response.data.message);
      payload.setIsLoadingresend(false);
    }
    // payload.setIsLoading(false);
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    // payload.setIsLoading(false);
    payload.setIsLoadingresend(false);
  }
}

function* updatenameprofile({ payload }) {
  try {
    let data = {
      id: payload.id,
      firstName: payload.firstName,
      lastname: payload.lastName,
      modifiedBy: payload.modifiedBy,
    };
    payload.setLoading(false);

    const response = yield instance.put(
      "signup_svc/pv/users/updateBasicProfile",
      data
    );
    if (response.data.code === 0) {
      yield put(push(`/Profile`));
      payload.setLoading(false);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    payload.setLoading(false);
  }
}

function* uploadimageuser({ payload }) {
  try {
    const formData = new FormData();
    formData.append("uploadImage", payload.uploadimage);
    const response = yield instance.post(
      `signup_svc/pv/users/addUserImage?userId=${payload.id}&environment=dev`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.code === 0) {
      // toast.success(response.data.message);
      yield put(uploadprofileimagesuccess(response.data.result));
    } else {
      customSnackBar(response.data.message);
    }
  } catch (error) {
    // yield sagaErrorHandler(error.response.data);
    // payload.setIsLoading(false);
  }
}

function* getUserPermission({ payload }) {
  // const token = yield select(makeSelectAuthToken());
  try {
    // payload.setIsLoading(true);
    // Reload the window
    const useridlogin = payload.userID;
    const defaultBusiness = payload.businessID;
    const response = yield instance.get(
      `/business_svc/pb/get_userroles/?userId=${useridlogin}&businessId=${defaultBusiness}`
      // `/business_svc/pb/get_userroles/?userId=19&businessId=1`
    );
    // {
    //   headers: {
    //     Authorization: `Token  ${token}`,
    //   },
    // });
    yield put(permissionModuleSucess(response?.data?.result));
    // payload.setIsLoading(false);
  } catch (error) {
    yield sagaErrorHandler(error.response.data);
    payload.setIsLoading(false);
  }
}

//////////////////////////////////////

// export function* watchLogin() {
//   yield takeLatest(LOGIN, loginUser);
// }
export function* watchRegister() {
  yield takeLatest(REGISTER_USER, registerUser);
}
export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordRequest);
}
export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordRequest);
}
export function* watchChangePassword() {
  yield takeLatest(CHANGE_PASSWORD, changePasswordRequest);
}

///////////////////////////////////////////////////binders for plexar
export function* registerapibinder() {
  yield takeLatest(REGISTERAPI, registeruser);
}

export function* forgetPasswordBinder() {
  yield takeLatest(FORGET_PASSWORD, forgetPassword);
}
export function* sendotpbinder() {
  yield takeLatest(SEND_OTP_EMAIL, sendotpemail);
}
export function* setpasswordbinder() {
  yield takeLatest(SET_USER_PASSWORD, setuserrpasswordfunc);
}
export function* adduserprofilebinder() {
  yield takeLatest(ADD_USER_PROFILE, adduserprofilefunc);
}
export function* addusermobilebinder() {
  yield takeLatest(ADD_USER_MOBILE, addusermobilefunc);
}
export function* mobileotpverifyybinder() {
  yield takeLatest(MOBILE_OTP_VERIFY, mobileotpvrtifyy);
}
export function* getuserbyloginbinder() {
  yield takeLatest(GET_USER_DATA_AFTER_LOGIN, getuserdetaillogin);
}
export function* addsecondaryemailbinder() {
  yield takeLatest(ADD_SECONDARY_EMAIL, addsecondaryemailfunc);
}
export function* verifysecondarymailbinder() {
  yield takeLatest(VERIFY_SECONDARY_EMAIL, verifysecondaryemailfunc);
}
export function* sendotpsecondarybinder() {
  yield takeLatest(SEND_OTP_SECONDRY_EMAIL, sendotpsecondaryfunc);
}

export function* addsecondaryphonebinder() {
  yield takeLatest(ADD_SECONDARY_PHONE_NUMBER, addsecondaryphonefunc);
}

export function* verifysecondarymobilebinder() {
  yield takeLatest(VERIFY_SECONDARY_MOBILE, verifysecondarymobilefunc);
}
export function* resendsecondarymobilebinder() {
  yield takeLatest(RESEND_OTP_MOBILE, sendotpsecondarymobilefunc);
}

export function* getallusersgenderbinder() {
  yield takeLatest(GET_ALL_GENDERS, getallgendersfunc);
}

export function* sendgenderbinder() {
  yield takeLatest(SEND_GENDER, sendgenderfunc);
}

export function* adddobbinder() {
  yield takeLatest(ADD_DOB, addsobfunctionfunc);
}

export function* signinuserfuncbinder() {
  yield takeLatest(SIGNIN_USER, signinuserfunc);
}

export function* otpmobileprimaryfuncbinder() {
  yield takeLatest(RESEND_MOBILE_PRIMARY_OTP, resendprimarymobilefunc);
}

export function* resendprimaryemailnewotpfuncbinder() {
  yield takeLatest(RESEND_OTP_NEW_PRIMARY_EMAIL, resendprimaryemailnewotpfunc);
}

export function* resendotpsecondaryfuncbinder() {
  yield takeLatest(RESEND_OTP_SECONDARY_EMAIL, resendotpsecondaryfunc);
}

export function* resendotpsecondarymobilefuncbinder() {
  yield takeLatest(NEW_OTP_RESEND_PRIMARY_MOBILE, resendprimarymobilenewfunc);
}

export function* updateuserprofilebinder() {
  yield takeLatest(UPDATE_USER_NAME, updatenameprofile);
}

export function* uploadimageuserbinder() {
  yield takeLatest(UPLOAD_PROFILE_IMG, uploadimageuser);
}

export function* permissionModule() {
  yield takeLatest(PERMISSION_API, getUserPermission);
}

export function* watchchangeUserPasswords() {
  yield takeLatest(CHANGE_USERS_PASSWORD, changeUserPasswordRequests);
}
export function* watchRestsPassword() {
  yield takeLatest(CHANGE_USER_PASSWORD, resetUserPasswordRequest);
}

///////////////////////////////

export default function* authSaga() {
  yield all([
    // fork(watchLogin),
    fork(watchRegister),
    fork(permissionModule),
    fork(watchchangeUserPasswords),
    fork(watchRestsPassword),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchChangePassword),
    fork(registerapibinder),
    fork(sendotpbinder),
    fork(setpasswordbinder),
    fork(adduserprofilebinder),
    fork(addusermobilebinder),
    fork(mobileotpverifyybinder),
    fork(getuserbyloginbinder),
    fork(addsecondaryemailbinder),
    fork(verifysecondarymailbinder),
    fork(sendotpsecondarybinder),
    fork(addsecondaryphonebinder),
    fork(verifysecondarymobilebinder),
    fork(resendsecondarymobilebinder),
    fork(getallusersgenderbinder),
    fork(sendgenderbinder),
    fork(adddobbinder),
    fork(forgetPasswordBinder),
    fork(signinuserfuncbinder),
    fork(otpmobileprimaryfuncbinder),
    fork(resendprimaryemailnewotpfuncbinder),
    fork(resendotpsecondaryfuncbinder),
    fork(resendotpsecondarymobilefuncbinder),
    fork(updateuserprofilebinder),
    fork(uploadimageuserbinder),
  ]);
}
