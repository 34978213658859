import axios from "axios";
import Cookies from "js-cookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

var CryptoJS = require("crypto-js");

export const validateEmail = (email) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(email);
};

export const setStorageData = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageData = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const setCookiesData = (key, value) => {
  try {
    if (typeof value !== "string") {
      throw new Error("Invalid data format");
    }
    const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 2);
    Cookies.set(key, encryptedData, { expires: expirationDate });
    return encryptedData;
  } catch (error) {
    console.error("Error in setCookiesData:", error);
    // Handle the error gracefully, e.g., by returning an error message.
    return "Error: Unable to set data in cookies";
  } // You might want to return the encrypted data for further debugging
};

export const setCookiesDataWithTime = (key, value, rememberMe) => {
  try {
    if (typeof value !== "string") {
      throw new Error("Invalid data format");
    }
    const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
    const expirationDate = new Date();
    if (rememberMe) {
      expirationDate.setDate(expirationDate.getDate() + 7);
    } else {
      expirationDate.setDate(expirationDate.getDate() + 1);
    }
    Cookies.set(key, encryptedData, { expires: expirationDate });
    return encryptedData;
  } catch (error) {
    console.error("Error in setCookiesData:", error);
    // Handle the error gracefully, e.g., by returning an error message.
    return "Error: Unable to set data in cookies";
  } // You might want to return the encrypted data for further debugging
};

const decruptUrl = (str) => {
  return CryptoJS.AES.decrypt(str, "secret_key").toString(CryptoJS.enc.Utf8);
};

export const decodeURL = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectInfoParam = params.get("redirectInfo");
  const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
  try {
    const redirectObject = JSON.parse(decodedRedirectInfo);
    const decryptedURL = decruptUrl(redirectObject.encryptedURL);
  } catch (e) {
    console.error("Error parsing redirectInfo:", e);
  }
};

export const getCookiesData = (key) => {
  const encryptedData = Cookies.get(key);

  try {
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, "Dad").toString(
        CryptoJS.enc.Utf8
      );

      return JSON.parse(decryptedData);
    } else {
      return null; // Return null or handle the case where the cookie data is missing
    }
  } catch (error) {
    console.error("Error in getCookiesData:", error);
    // Handle decryption or parsing errors gracefully
    return null; // Return null or handle the error as appropriate
  }
};

export const removeAllCookies = () => {
  const cookies = Cookies.get();
  for (const cookieName in cookies) {
    Cookies.remove(cookieName); // Remove each cookie by name
  }
};
export const ClearAllSession = () => {
  const cookies = Cookies.get();
  for (const cookieName in cookies) {
    Cookies.remove(cookieName); // Remove each cookie by name
    localStorage.clear();
  }
  // return localStorage.clear();
};

export const removeAllLocalStorage = () => {
  return localStorage.clear();
};

/* -------------------------------------------------------------------------- */
/*                            Cookies in SubDomain                            */
/* -------------------------------------------------------------------------- */

export const setCookiesDataInSubDomain = (key, value) => {
  try {
    if (typeof value !== "string") {
      throw new Error("Invalid data format");
    }
    const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 2);
    Cookies.set(key, encryptedData, {
      expires: expirationDate,
      domain: ".plexaar.com",
    });
    return encryptedData;
  } catch (error) {
    console.error("Error in setCookiesData:", error);
    return "Error: Unable to set data in cookies";
  }
};

export const setCookiesDataWithTimeInSubDomain = (key, value, rememberMe) => {
  try {
    if (typeof value !== "string") {
      throw new Error("Invalid data format");
    }
    const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
    const expirationDate = new Date();
    if (rememberMe) {
      expirationDate.setDate(expirationDate.getDate() + 7);
    } else {
      expirationDate.setDate(expirationDate.getDate() + 1);
    }
    Cookies.set(key, encryptedData, { expires: expirationDate });
    return encryptedData;
  } catch (error) {
    console.error("Error in setCookiesData:", error);
    return "Error: Unable to set data in cookies";
  }
};

export const getCookiesDataInSubDomain = (key) => {
  const encryptedData = Cookies.get(key);
  try {
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, "Dad").toString(
        CryptoJS.enc.Utf8
      );
      return JSON.parse(decryptedData);
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error in getCookiesData:", error);
    return null;
  }
};

export const removeAllCookiesInSubDomain = () => {
  const cookies = Cookies.get();
  for (const cookieName in cookies) {
    Cookies.remove(cookieName, { domain: ".plexaar.com" });
  }
};

// const formatDate = (dateString) => {
//   if (!dateString) return ""; // Return empty string if dateString is not provided
//   const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   return `${year}-${month}-${day}`;
// };

export const getPrevUrls = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectInfoParam = params.get("redirectInfo");
  const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
  try {
    const redirectObject = JSON.parse(decodedRedirectInfo);
    const decryptedURL = decruptUrl(redirectObject.encryptedURL);
    return decryptedURL;
  } catch (e) {
    console.error("Error parsing redirectInfo:", e);
  }
};
export function encryptURL(currentURL) {
  return CryptoJS.AES.encrypt(currentURL, "9GxbhX0FNMP").toString();
}

// function to get device info
export const getDeviceFingerprints = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const { visitorId, components } = result;
  console.log(result, "COMPONENTS");
  return {
    uniqueId: visitorId, // Unique fingerprint ID
    browser: components.browser,
    os: components.os,
    device: components.device,
  };
};

export const getIPAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip; // Public IP address
  } catch (error) {
    console.error("Error fetching IP address: ", error);
    return null;
  }
};

export const getDeviceType = () => {
  const userAgent = navigator.userAgent;
  if (/Mobi|Android/i.test(userAgent)) {
    return "Mobile";
  } else if (/Tablet|iPad/i.test(userAgent)) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

export function getOSVersion() {
  const userAgent = navigator.userAgent;

  // Detect Windows
  if (userAgent.indexOf("Windows NT") !== -1) {
    const windowsVersion = userAgent.match(/Windows NT (\d+\.\d+)/);
    if (windowsVersion) return `Windows ${windowsVersion[1]}`;
  }

  // Detect macOS
  if (userAgent.indexOf("Mac OS X") !== -1) {
    const macVersion = userAgent.match(/Mac OS X (\d+[_\.]\d+[_\.]?\d*)/);
    if (macVersion) return `macOS ${macVersion[1].replace(/_/g, ".")}`;
  }

  // Detect iOS
  if (/iPhone|iPad|iPod/.test(userAgent)) {
    const iosVersion = userAgent.match(/OS (\d+[_\.]\d+[_\.]?\d*)/);
    if (iosVersion) return `iOS ${iosVersion[1].replace(/_/g, ".")}`;
  }

  // Detect Android
  if (userAgent.indexOf("Android") !== -1) {
    const androidVersion = userAgent.match(/Android (\d+[_\.]?\d*)/);
    if (androidVersion) return `Android ${androidVersion[1]}`;
  }

  return "Unknown OS";
}

console.log(getOSVersion());
